import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import { selectTextCollection } from 'store/modules/slice';
import { TextCollectionNoAttached } from './TextCollectionNoAttached';
import { TextCollectionsTable } from './TextCollectionsTable';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const TextCollectionModal: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string | null>(null);
  const { isOpen, onClose } = props;
  const textCollections = useSelector(selectTextCollection);
  const [isTextCollections] = useState(Boolean(textCollections?.length));

  const onCloseHandler = (): void => {
    onClose();
  };

  const onChangeTable = (value: string): void => {
    setSelected(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      size={isTextCollections ? 'xl' : 'lg'}
      title={isTextCollections ? 'Select Text Collection' : 'No Text Collection was found'}
      tooltipText='Indication(s) and SSI content will be added from selected Text Collection automatically'
    >
      <div className="modal-body">
        {
          isTextCollections
            ? (<TextCollectionsTable onChangeTable={onChangeTable} textCollections={textCollections} />)
            : (<TextCollectionNoAttached />)
        }

      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-start">
          <button
            className="me-3 btn btn-outline-primary custom-button"
            onClick={(): void => onClose()}
            type="button"
          >
            <span className="me-2">Cancel</span>
          </button>

          {
            isTextCollections
              ? (<Button
                label="Select"
                type="button"
                isDisabled={!selected?.length}
                onClick={(): void => {}}
              />)
              : (<Button
                label="Continue"
                type="button"
                onClick={(): void => navigate('/combinations')}
              />)
          }
        </div>
      </div>
    </Modal>
  );
};
