import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import LayoutPreview from 'components/LayoutPreview';
import type { Layout, ScreenToView } from 'types';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
import ActionPanel from './ActionPanel';
import CancelPanel from './CancelPanel';
import styles from './styles.module.scss';

interface SectionProps {
  id: string;
  displayName: string;
  layouts: Layout[];
  layoutVariants: Layout[];
  width: number;
  screenId: string;
  matchedScreens: ScreenToView[];
  scrollContainer?: React.RefObject<HTMLDivElement>;
  onCnahgeLayoutVariant: (layout: Layout | null, screen: ScreenToView) => void;
}

const Section: React.FC<SectionProps> = ({
  layouts,
  layoutVariants,
  id: sectionId,
  displayName,
  width,
  scrollContainer,
  screenId,
  matchedScreens,
  onCnahgeLayoutVariant,
}) => {
  // accroding to the business logic only one layout should be in the section,
  // but in the theory Veeva may return more than one
  const activeLayout = layouts[0];
  const activeLayoutIdx = layoutVariants.findIndex(variant => variant.id === activeLayout.id);
  const [isSectionHighlited, setIsSectionHighlited] = useState(false);
  const [correspondingScreen, setCorrespondingScreen] = useState<ScreenToView | null>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const scrollSectionToCenter = (): void => {
    setTimeout(() => {
      sectionRef.current?.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
    });
  };

  const fixSectionTopPosition = (): void => {
    const containerTop = scrollContainer?.current?.getBoundingClientRect()?.top ?? 0;
    const sectionTop = sectionRef.current?.getBoundingClientRect()?.top ?? 0;
    if (Math.ceil(sectionTop) < Math.ceil(containerTop)) {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView();
      });
    }
  };

  useEffect(() => {
    const focusSection = (focusedSectionId: string): void => {
      setIsSectionHighlited(sectionId === focusedSectionId);
    };

    const scrollSection = (scrolledSection: string): void => {
      if (sectionId === scrolledSection) {
        scrollSectionToCenter();
      }
    };

    eventEmitter.addListener(EVENTS.FOCUS_SECTION_RIGHT, focusSection);
    eventEmitter.addListener(EVENTS.SCROLL_SECTION_RIGHT, scrollSection);

    return () => {
      eventEmitter.removeListener(EVENTS.FOCUS_SECTION_RIGHT, focusSection);
      eventEmitter.removeListener(EVENTS.SCROLL_SECTION_RIGHT, scrollSection);
    };
  }, [sectionId]);

  useEffect(() => {
    const matchedLayout = layoutVariants.find(
      (layoutVariant => layouts.find(layout => layout.id === layoutVariant.id)),
    );
    if (matchedLayout) {
      const currentMatchedScreen = matchedScreens.find(matchedScreen =>
        matchedScreen.layouts.find(matchedScreenLayout => matchedScreenLayout.id === matchedLayout.id));
      if (currentMatchedScreen) {
        setCorrespondingScreen(currentMatchedScreen);
      }
    }
  }, [layoutVariants, layouts]);

  const isEmptySection = _.isEmpty(layouts);

  if (isEmptySection) {
    return null;
  }

  const onClick = (): void => {
    const reversedMatchedScreens: ScreenToView[] = [...matchedScreens].reverse();
    for (const matchedScreen of reversedMatchedScreens) {
      eventEmitter.emit(EVENTS.EXPAND_ACCORDION, matchedScreen?.id);
    }
    fixSectionTopPosition();
  };

  const onChangeLayout = (layout: Layout): void => {
    const currentScreen = matchedScreens.find(matchedScreen =>
      matchedScreen.layouts.find(matchedScreenLayout => matchedScreenLayout.id === layout.id));

    if (currentScreen) {
      onCnahgeLayoutVariant(layout, currentScreen);
      eventEmitter.emit(EVENTS.EXPAND_ACCORDION, currentScreen?.id || screenId, layout.id);
      fixSectionTopPosition();
    }
  };

  const onClearLayout = (): void => {
    if (correspondingScreen) {
      onCnahgeLayoutVariant(null, correspondingScreen);
      fixSectionTopPosition();
    }
  };

  const classes = classNames(
    styles.sectionContainer,
    {
      [styles.withLayoutVariants]: !!layoutVariants.length,
      [styles.highlighted]: isSectionHighlited,
    },
  );

  return (
    <AccessibleDiv onClick={onClick} className={classes} data-testid="simulation-section">
      <div className={styles.sectionStickyPanel}>
        <div>
          {!!layoutVariants.length && (
            <ActionPanel
              displayName={displayName}
              layoutVariantIdx={activeLayoutIdx}
              layoutVariants={layoutVariants}
              onChangeLayout={onChangeLayout}
            />
          )}
        </div>
      </div>

      <div ref={sectionRef} title={displayName} className={styles.section} style={{ width }}>
        {layouts.map(({ id, documentId }) => (
          <LayoutPreview
            key={id}
            documentId={documentId}
          />
        ))}
      </div>

      <div className={styles.sectionStickyPanel}>
        <div>
          {activeLayoutIdx >= 0 && (
            <CancelPanel
              className="shadow"
              onClick={onClearLayout}
            />
          )}
        </div>
      </div>
    </AccessibleDiv>
  );
};

export default Section;
