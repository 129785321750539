import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import NavBar from 'components/NavBar/NavBar';
import {
  getIndependentFiltersDataRequest,
  selectIndependentFiltersError,
  selectResourceTypesError,
  resetFilters,
  setSelectedFilters,
} from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  reset as resetJobZero,
  selectJobZeroData,
  selectJobZeroDataLoadingStatus,
  selectLastSelectedDocumentId,
  setLastSelectedDocumentId,
} from 'store/jobZeroSlice';
import FilterBar from './components/FilterBar';
import InitialTableContent from './components/InitialTableContent';
import JobZeroContent from './components/JobZeroContent';
import NoContentFound from './components/NoContentFound';
import Spinner from './components/Spinner';
import styles from './Filters.module.scss';
import { useLoadNextPage } from './useLoadNextPage';

const Filters: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const independentFiltersError = useAppSelector(selectIndependentFiltersError);
  const resourceTypesError = useAppSelector(selectResourceTypesError);
  const filtersError = independentFiltersError || resourceTypesError;
  const jobZeroData = useAppSelector(selectJobZeroData);
  const jobZeroDataLoading = useAppSelector(selectJobZeroDataLoadingStatus);
  const lastSelectedDocumentId = useAppSelector(selectLastSelectedDocumentId);
  const [isJobZeroRequestSubmitted, setIsJobZeroRequestSubmitted] =
    useState(false);
  const shouldDisplayJobZeroDocuments = jobZeroData && jobZeroData.length > 0;
  const lastSelectedDocumentRef = useRef<HTMLTableRowElement>(null);
  const {
    observerTarget,
    isNextPageLoading,
  } = useLoadNextPage(shouldDisplayJobZeroDocuments, jobZeroDataLoading);

  const resetFiltersPage = useCallback((): void => {
    dispatch(resetJobZero());
    dispatch(
      setSelectedFilters({
        selectedCountry: '',
        selectedProductFamily: '',
        selectedResourceType: '',
        selectedDeliveryMethod: '',
        selectedDeploymentSupplier: '',
      }),
    );
    setIsJobZeroRequestSubmitted(false);
  }, [dispatch]);

  const onJobZeroSelectHandler = (jobZeroId: string, moduleBundleId: string): void => {
    dispatch(setLastSelectedDocumentId(jobZeroId));
    navigate(`/modules?jobzero=${jobZeroId}&bundle=${moduleBundleId}`);
  };

  useEffect(() => {
    if (lastSelectedDocumentRef.current) {
      lastSelectedDocumentRef.current.scrollIntoView({ block: 'end' });
    }
  }, [lastSelectedDocumentRef.current]);

  useEffect(() => {
    dispatch(getIndependentFiltersDataRequest());
  }, [dispatch]);

  if (filtersError) {
    dispatch(resetFilters());
    dispatch(resetJobZero());
  }

  let tableContent = <InitialTableContent />;

  if (jobZeroDataLoading && !isNextPageLoading) {
    tableContent = <Spinner />;
  }

  if (
    isJobZeroRequestSubmitted &&
    !jobZeroDataLoading &&
    jobZeroData &&
    jobZeroData.length < 1
  ) {
    tableContent = <NoContentFound />;
  }

  if (shouldDisplayJobZeroDocuments) {
    tableContent = (
      <JobZeroContent
        ref={lastSelectedDocumentRef}
        jobZeroData={jobZeroData}
        lastSelectedDocumentId={lastSelectedDocumentId}
        onJobZeroSelectHandler={onJobZeroSelectHandler}
      />
    );
  }

  return (
    <>
      <NavBar onBrandClick={resetFiltersPage} />
      <main
        className={`container-fluid mx-lg-5 px-3 ${styles.contentContainer}`}
      >
        <h4 className="p-0 pb-3 m-0 border-bottom" style={{ width: 'auto' }}>
          Select Criteria to Find Modular Content
        </h4>
        <div className="d-flex pt-4">
          <FilterBar onJobZeroRequestSubmitted={setIsJobZeroRequestSubmitted} />
          <div className={`ms-4 flex-grow-1 ${styles.tableBlock}`}>
            <table className={`table ${styles.table}`}>
              <thead>
                <tr>
                  <th>Document Number</th>
                  <th>Name</th>
                  <th>Module Bundle</th>
                </tr>
              </thead>
              <tbody>{tableContent}</tbody>
              <tfoot><tr ref={observerTarget} /></tfoot>
            </table>
            {isNextPageLoading && (
              <div className='d-flex align-items-center mt-2'>
                <AccessibleDiv className='spinner-border text-secondary' role='output' />
                <div className='ms-2'>Loading</div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Filters;
