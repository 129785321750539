import _ from 'lodash';
import { ModulesBundleWithSelection } from './helpers';

function isBundleMustBeSelected(populatedClusters: number[], bundle: ModulesBundleWithSelection): boolean {
  return (_.last(populatedClusters) ?? 0) > (bundle.cluster ?? 0)
    && !populatedClusters.includes(bundle.cluster as number)
    && bundle.selectedCount === 0
    && Number.isFinite(bundle.cluster);
}

export function getPopulatedClusters(bundles: ModulesBundleWithSelection[]): number[] {
  return bundles
    .filter(bundle => bundle.selectedCount > 0)
    .map(({ cluster }) => (cluster as number))
    .filter(Number.isFinite);
}

export function recomputeClusters(bundles: ModulesBundleWithSelection[]): ModulesBundleWithSelection[] {
  const populatedClusters = getPopulatedClusters(bundles);

  return bundles.map(bundle => ({
    ...bundle,
    clusterSelectionRequired: isBundleMustBeSelected(populatedClusters, bundle),
  }));
}
