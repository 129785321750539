import { capitalize } from 'inflection';
import React from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import styles from './Button.module.scss';

type Props = {
  type: 'reset' | 'button' | 'submit' | undefined;
  isDisabled?: boolean;
  label: string;
  styleOverride?: React.CSSProperties;
  intent?: 'primary' | 'secondary' | 'danger';
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  formId?: string;
  isLoading?: boolean;
  dataTestId?: string;
  className?: string;
};

const Button: React.FC<Props> = (props) => {
  const {
    isDisabled = false,
    label,
    styleOverride,
    intent = 'primary',
    type,
    onClick,
    formId,
    isLoading,
    dataTestId = '',
    className = '',
  } = props;
  const buttonIntentClass = `button${capitalize(intent)}`;

  return (
    <button
      type={type}
      className={`${styles.button} ${styles[buttonIntentClass]} ${className}`}
      disabled={isDisabled}
      style={styleOverride}
      onClick={onClick}
      form={formId}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <AccessibleDiv
          className="spinner-border spinner-border-sm text-light"
          role="output"
        >
          <span className="visually-hidden">Loading...</span>
        </AccessibleDiv>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
