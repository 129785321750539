/* eslint-disable jest/require-hook */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from './authSlice';
import channelsReducer from './channelsSlice';
import filtersReducer from './filtersSlice';
import jobZeroReducer from './jobZeroSlice';
import modulesReducer, { modulesInitialState } from './modules/slice';
import { ModulesState } from './modules/types';
import { saveToSessionStorage, loadFromSessionStorage } from './persistentState';
import recipeReducer from './recipeSlice';
import rootSaga from './sagas';
import { SimulationState } from './simulation';
import simulationReducer from './simulationSlice';
import userReducer from './userSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    filters: filtersReducer,
    jobZero: jobZeroReducer,
    modules: modulesReducer,
    recipe: recipeReducer,
    channels: channelsReducer,
    simulation: simulationReducer,
  },
  preloadedState: ((): { simulation?: SimulationState; modules: ModulesState } => {

    const { simulation, modulesGroups } = loadFromSessionStorage<{
      simulation?: SimulationState;
      modulesGroups?: ModulesState['groups'];
    }>() || {};

    return {
      simulation,
      modules: { ...modulesInitialState, groups: { ...modulesGroups } },
    };
  })(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

store.subscribe(() => {
  const { simulation, modules } = store.getState();
  saveToSessionStorage({ simulation, modulesGroups: modules.groups });
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
