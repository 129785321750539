// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__JBBzO {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 15px;
}
.Button_button__JBBzO:disabled {
  cursor: default;
}
.Button_buttonPrimary__xHEX\\+ {
  background-color: #00857C;
  color: white;
}
.Button_buttonPrimary__xHEX\\+:active, .Button_buttonPrimary__xHEX\\+:hover {
  background-color: #006760;
}
.Button_buttonPrimary__xHEX\\+:disabled {
  background-color: #86C2BF;
}
.Button_buttonSecondary__dc8mU {
  background-color: white;
  border: 1px solid #00857C;
  color: #00857C;
  padding: 6px 15px;
}
.Button_buttonSecondary__dc8mU:active, .Button_buttonSecondary__dc8mU:hover {
  border-color: #005e57;
  color: #005e57;
}
.Button_buttonSecondary__dc8mU:disabled {
  border-color: #86C2BF;
  color: #86C2BF;
}
.Button_buttonDanger__coUgU {
  background-color: #dc3545;
  color: white;
}
.Button_buttonDanger__coUgU:hover {
  background-color: #ab2633;
}
.Button_buttonDanger__coUgU:disabled {
  background-color: #f87784;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,yBAAA;EACA,YAAA;AADJ;AAGI;EAEE,yBAAA;AAFN;AAKI;EACE,yBAAA;AAHN;AAOE;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;AALJ;AAOI;EAEE,qBAAA;EACA,cAAA;AANN;AASI;EACE,qBAAA;EACA,cAAA;AAPN;AAWE;EACE,yBAAA;EACA,YAAA;AATJ;AAWI;EACE,yBAAA;AATN;AAYI;EACE,yBAAA;AAVN","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.button {\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  padding: 7px 15px;\n\n  &:disabled {\n    cursor: default;\n  }\n\n  &Primary {\n    background-color: #00857C;\n    color: white;\n\n    &:active,\n    &:hover {\n      background-color: #006760;\n    }\n\n    &:disabled {\n      background-color: #86C2BF;\n    }\n  }\n\n  &Secondary {\n    background-color: white;\n    border: 1px solid #00857C;\n    color: #00857C;\n    padding: 6px 15px;\n\n    &:active,\n    &:hover {\n      border-color: #005e57;\n      color: #005e57;\n    }\n\n    &:disabled {\n      border-color: #86C2BF;\n      color: #86C2BF;\n    }\n  }\n\n  &Danger {\n    background-color: #dc3545;\n    color: white;\n\n    &:hover {\n      background-color: #ab2633;\n    }\n\n    &:disabled {\n      background-color: #f87784;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__JBBzO`,
	"buttonPrimary": `Button_buttonPrimary__xHEX+`,
	"buttonSecondary": `Button_buttonSecondary__dc8mU`,
	"buttonDanger": `Button_buttonDanger__coUgU`
};
export default ___CSS_LOADER_EXPORT___;
