/* eslint-disable max-len */

import React from 'react';

const QuestionMarkIcon: React.FC = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.4625 13.5C9.725 13.5 9.947 13.4092 10.1285 13.2277C10.3095 13.0467 10.4 12.825 10.4
      12.5625C10.4 12.3 10.3095 12.0782 10.1285 11.8973C9.947 11.7157 9.725 11.625 9.4625 11.625C9.2
      11.625 8.978 11.7157 8.7965 11.8973C8.6155 12.0782 8.525 12.3 8.525 12.5625C8.525 12.825 8.6155
      13.0467 8.7965 13.2277C8.978 13.4092 9.2 13.5 9.4625 13.5ZM8.7875 10.6125H10.175C10.175 10.2
      10.222 9.875 10.316 9.6375C10.4095 9.4 10.675 9.075 11.1125 8.6625C11.4375 8.3375 11.6938 8.028
      11.8813 7.734C12.0688 7.4405 12.1625 7.0875 12.1625 6.675C12.1625 5.975 11.9062 5.4375 11.3937
      5.0625C10.8812 4.6875 10.275 4.5 9.575 4.5C8.8625 4.5 8.2845 4.6875 7.841 5.0625C7.397 5.4375
      7.0875 5.8875 6.9125 6.4125L8.15 6.9C8.2125 6.675 8.35325 6.43125 8.57225 6.16875C8.79075 5.90625
      9.125 5.775 9.575 5.775C9.975 5.775 10.275 5.88425 10.475 6.10275C10.675 6.32175 10.775 6.5625
      10.775 6.825C10.775 7.075 10.7 7.30925 10.55 7.52775C10.4 7.74675 10.2125 7.95 9.9875 8.1375C9.4375
      8.625 9.1 8.99375 8.975 9.24375C8.85 9.49375 8.7875 9.95 8.7875 10.6125ZM9.5 16.5C8.4625 16.5 7.4875
      16.303 6.575 15.909C5.6625 15.5155 4.86875 14.9813 4.19375 14.3063C3.51875 13.6313 2.9845 12.8375
      2.591 11.925C2.197 11.0125 2 10.0375 2 9C2 7.9625 2.197 6.9875 2.591 6.075C2.9845 5.1625 3.51875
      4.36875 4.19375 3.69375C4.86875 3.01875 5.6625 2.48425 6.575 2.09025C7.4875 1.69675 8.4625 1.5 9.5
      1.5C10.5375 1.5 11.5125 1.69675 12.425 2.09025C13.3375 2.48425 14.1313 3.01875 14.8063 3.69375C15.4813
      4.36875 16.0155 5.1625 16.409 6.075C16.803 6.9875 17 7.9625 17 9C17 10.0375 16.803 11.0125 16.409
      11.925C16.0155 12.8375 15.4813 13.6313 14.8063 14.3063C14.1313 14.9813 13.3375 15.5155 12.425
      15.909C11.5125 16.303 10.5375 16.5 9.5 16.5ZM9.5 15C11.175 15 12.5937 14.4187 13.7562 13.2562C14.9187
      12.0937 15.5 10.675 15.5 9C15.5 7.325 14.9187 5.90625 13.7562 4.74375C12.5937 3.58125 11.175 3 9.5
      3C7.825 3 6.40625 3.58125 5.24375 4.74375C4.08125 5.90625 3.5 7.325 3.5 9C3.5 10.675 4.08125 12.0937
      5.24375 13.2562C6.40625 14.4187 7.825 15 9.5 15Z" fill="#ACAFBF"/>
    </svg>
  );
};

export default QuestionMarkIcon;
