import React, { Fragment, memo } from 'react';
import BiLayersFill from 'assets/images/bi-layers-fill.svg';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import { ModulesSection } from 'store/modules/types';
import styles from '../Combinations.module.scss';
import { CombinationSparseArray, isCombinationItemWithGroup } from '../useCombinations';

type Props = {
  sectionsToView: (readonly [ModulesSection, number])[];
  combination: CombinationSparseArray;
  index: number;
  active: boolean;
  selected: boolean;
  onClick: (idx: number) => void;
  onToggle: (idx: number) => void;
};

const CombinationsListItem: React.FC<Props> = (props) => {

  const {
    sectionsToView,
    combination,
    index,
    active,
    selected,
    onClick,
    onToggle,
  } = props;

  const activeClass = active ? styles.container__left__combinations__combination_active : '';
  const handler = (): void => onClick(index);

  return (
    <AccessibleDiv
      onClick={handler}
      onKeyUp={handler}
      className={`${activeClass} ${styles.container__left__combinations__combination}`}
    >
      <div className={styles.container__left__combinations__combination__header} >
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={(): void => onToggle(index)}
            checked={selected}
          />
          #{index + 1}
        </div>
        <span>Asset #</span>
      </div>
      <div className={ styles.container__left__combinations__combination__items }>
        {sectionsToView.map(([section, idx]) => {
          const item = combination[idx];
          let number: string | JSX.Element = 'none';
          if (item) {
            number = isCombinationItemWithGroup(item)
              ? <><BiLayersFill /> {item.modules.map(i => i + 1).join(', ')}</>
              : (item.moduleIdx + 1).toString();
          }

          return (
            <Fragment key={section.section.id}>
              <div className="zone">
                {section.section.displayname}
              </div>
              <div className="number">{number}</div>
            </Fragment>
          );
        })}
      </div>
    </AccessibleDiv>
  );
};

export default memo(CombinationsListItem);
