import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsTextCollectionModalIsOpen } from 'store/modules/slice';
import { useBundle } from '../hooks/useBundle';
import styles from '../Modules.module.scss';
import { BundleListItemGroup } from './BundleListItemGroup';
import { BundleListItemModule } from './BundleListItemModule';
import BundleListTitle from './BundleListTitle';
import { GroupModal } from './GroupModal';
import { TextCollectionModal } from './TextCollectionsModal/TextCollectionsModal';

type Props = {
  bundleIndex: number;
  makeRedirectToCombinations: () => void;
};

const BundleList: React.FC<Props> = (props) => {
  const { bundleIndex, makeRedirectToCombinations } = props;
  const [assetsVisible, setAssetsVisible] = useState(false);
  const {
    bundle,
    toggleAll,
    toggleModule,
    toggleGroup,
    createGroup,
    removeGroup,
    shiftModuleInGroup,
  } = useBundle(bundleIndex);

  const listRef = useRef<HTMLDivElement>(null);
  const lastGroupRef = useRef<HTMLElement>(null);

  const [groupModalIsOpen, setGroupModalIsOpen] = useState(false);
  const isTextCollectionOpen = useSelector(selectIsTextCollectionModalIsOpen);

  const groupsLengthRef = useRef(bundle?.groups ? bundle.groups.length : -1);

  const toggleNumbersHandler = (value: boolean): void => {
    setAssetsVisible(value);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    groupsLengthRef.current = bundle?.groups ? bundle.groups.length : -1;
  }, [bundleIndex]);

  useEffect(() => {
    const groups = bundle?.groups;
    if (!groups) {
      groupsLengthRef.current = -1;

      return;
    }

    if (groupsLengthRef.current < groups.length && lastGroupRef.current) {
      lastGroupRef.current.scrollIntoView();
    }
    groupsLengthRef.current = groups.length;
  }, [bundle?.groups]);

  if (!bundle) {
    return null;
  }

  const moduleThumbnailsBlockClass = `mb-3 ${styles.moduleThumbnails}`;

  if (bundle.modules.length === 0) {
    return (
      <div className={moduleThumbnailsBlockClass}>You have no permissions to see modules.</div>
    );
  }

  return (
    <>
      <BundleListTitle
        id={bundle.id}
        title={bundle.type}
        selectedNumber={bundle.selectedCount}
        totalNumber={bundle.availableCount}
        onChange={toggleAll}
        toggleNumbersHandler={toggleNumbersHandler}
        onCreateGroup={(): void => setGroupModalIsOpen(true)}
      />
      <div className={moduleThumbnailsBlockClass} ref={listRef}>
        {bundle.modules.map(module => (
          <BundleListItemModule
            key={`${module.id}.${module.layoutId}`}
            module={module}
            onChange={(): void => toggleModule(module.index)}
            checked={module.selected}
            disabled={module.disabled}
            showNumbers={assetsVisible}
          />
        ))}
        {bundle.groups.map((group, idx) => (
          <BundleListItemGroup
            ref={(idx + 1) === bundle.groups.length ? lastGroupRef : undefined}
            key={group.index}
            group={group}
            checked={group.selected}
            onChange={(): void => toggleGroup(group.index)}
            onRemove={(): void => removeGroup(group.index)}
            onShitfModule={(module, shift): void => shiftModuleInGroup(group.index, module, shift)}
            showNumbers={assetsVisible}
          />
        ))}
      </div>

      <GroupModal
        isOpen={groupModalIsOpen}
        onClose={(): void => setGroupModalIsOpen(false)}
        modules={bundle.modules}
        onCreate={createGroup}
      />
      <TextCollectionModal
        isOpen={isTextCollectionOpen}
        onSelect={makeRedirectToCombinations}
      />
    </>
  );
};

export default BundleList;
