import { call, put, takeLatest } from 'redux-saga/effects';
import { getModuleBundleSimulation } from 'api/getModuleBundleSimulation';
import { clearStorageState } from 'store/persistentState';
import type { ModuleBundleActionPayload } from 'types';
import { getSessionId } from 'utils';
import {
  getModuleBundleTemplateDataRequest,
  getModuleBundleTemplateDataSuccess,
  getModuleBundleTemplateDataError,
  getModuleBundleTemplateDataStart,
  getTemplateDataRequest,
  getTemplateDataSuccess,
  getTemplateDataError,
  getTemplateDataStart,
} from './simulationSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* simulationModuleBundleSaga({
  payload,
}: PayloadAction<ModuleBundleActionPayload>): SagaIterator {
  try {
    yield put(getModuleBundleTemplateDataStart());
    const sessionId: string = yield call(getSessionId);

    const data = yield call(getModuleBundleSimulation, sessionId, payload);

    if (!data) {
      clearStorageState();

      throw new Error('user does not have sufficient priviliges to get module bundle data');
    }

    yield put(
      getModuleBundleTemplateDataSuccess(data),
    );
  } catch (err) {
    yield put(getModuleBundleTemplateDataError(
      err instanceof Error ? err.message : `Simulation Saga Error: ${JSON.stringify(err)}`,
    ));
  }
}

function* simulationTemplateSaga({
  payload,
}: PayloadAction<ModuleBundleActionPayload>): SagaIterator {
  try {
    yield put(getTemplateDataStart());
    const sessionId: string = yield call(getSessionId);

    const data = yield call(getModuleBundleSimulation, sessionId, payload);

    if (!data) {
      clearStorageState();

      throw new Error('user does not have sufficient priviliges to get template data');
    }

    yield put(
      getTemplateDataSuccess(data),
    );
  } catch (err) {
    yield put(getTemplateDataError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [
  takeLatest(getModuleBundleTemplateDataRequest.type, simulationModuleBundleSaga),
  takeLatest(getTemplateDataRequest.type, simulationTemplateSaga),
];
