import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetModulesDataResponse } from 'api/getModulesData';
import { SAGA_STATUS } from 'const';
import { addBundleGroup, getPersistentBundlesGroups, replaceBundleGroups } from './helpers';
import {
  ModulesState, ModulesActioinRequestPayload,
  CreateGroupActionPayload, RemoveGroupActionPayload, ShiftModuleInGroupActionPayload,
  ModulesBundle,
  TextCollectionType,
} from './types';
import type { RootState } from '../index';

export const modulesInitialState: ModulesState = {
  status: SAGA_STATUS.IDLE,
  error: '',
  groups: {},
  textCollectionMetadata: [],
  isTextCollectionModalOpen: false,
};

const getGroupsKey = (info: NonNullable<ModulesState['info']>): string => {
  return `jobzero=${info.jobZero.id}&bundle=${info.moduleBundle.id}`;
};

const mutateGroups = (
  state: ModulesState, bundles: ModulesBundle[],
): void => {
  const { info } = state;
  if (!info) {
    return;
  }
  const key = getGroupsKey(info);
  state.groups[key] = state.groups[key] || {};
  state.groups[key] = getPersistentBundlesGroups(bundles);
};

export const modulesSlice = createSlice({
  name: 'modules',
  initialState: modulesInitialState,
  reducers: {
    getModulesStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
      state.sections = undefined;
      state.bundles = undefined;
      state.info = undefined;
    },
    getModulesSuccess: (state, action: PayloadAction<GetModulesDataResponse>) => {
      const { payload: {
        jobZero,
        moduleBundle,
        bundles,
        sections,
        textCollectionMetadata,
      } } = action;
      state.info = { jobZero, moduleBundle };
      state.sections = sections;
      state.textCollectionMetadata = textCollectionMetadata;

      let bundlesForState: ModulesBundle[] = bundles.map((bundle, bundleIndex) => ({
        ...bundle,
        index: bundleIndex,
        modules: bundle.modules.map((module, moduleIndex) => ({
          ...module,
          selected: bundle.modules.length === 1,
          disabled: false,
          index: moduleIndex,
        })),
        groups: [],
      }));
      bundlesForState = (state.groups[getGroupsKey(state.info)] || []).reduce(
        (acc, bundleGroups) => {
          const { bundleIndex, groups } = bundleGroups;

          return groups.reduce(
            (innerAcc, moduleIndexes) => addBundleGroup(innerAcc, bundleIndex, moduleIndexes),
            acc,
          );
        },
        bundlesForState,
      );

      state.bundles = bundlesForState;
      state.status = SAGA_STATUS.IDLE;
      mutateGroups(state, bundlesForState);
    },
    getModulesError: (state, action: PayloadAction<string | undefined>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload ?? 'Unknown modules error';
    },
    openTextCollectionModal: (state, action: PayloadAction<boolean>) => {
      state.isTextCollectionModalOpen = action.payload;
    },
    updateModulesBundles: (state, action) => {
      state.bundles = [ ...action.payload];
    },
    createGroup: (state, action: CreateGroupActionPayload) => {
      const { bundleIndex, moduleIndexes } = action.payload;
      const { bundles = [] } = state;
      const updatedBundles = addBundleGroup(bundles, bundleIndex, moduleIndexes);
      state.bundles = updatedBundles;
      mutateGroups(state, updatedBundles);
    },
    removeGroup: (state, action: RemoveGroupActionPayload) => {
      const { bundleIndex, groupIndex } = action.payload;
      const { bundles = [] } = state;
      const updatedGroups = (bundles[bundleIndex]?.groups ?? []).map(i => i); // for sparse array
      delete updatedGroups[groupIndex];
      const updatedBundles = replaceBundleGroups(bundles, bundleIndex, updatedGroups);
      state.bundles = updatedBundles;
      mutateGroups(state, updatedBundles);
    },
    shiftModuleInGroup: (state, action: ShiftModuleInGroupActionPayload) => {
      const {
        bundleIndex,
        groupIndex,
        moduleIndex,
        shift,
      } = action.payload;
      const { bundles = [] } = state;
      const bundle = bundles[bundleIndex];
      const group = bundle?.groups[groupIndex];
      const srcIdx = group?.modules.findIndex(module => module.index === moduleIndex);
      if (!group || srcIdx === undefined || srcIdx < 0) {
        return;
      }

      const swapIdx = Math.min(Math.max(srcIdx + shift, 0), group.modules.length - 1);
      const updatedModules = [...group.modules];
      [updatedModules[srcIdx], updatedModules[swapIdx]] = [updatedModules[swapIdx], updatedModules[srcIdx]];

      const updatedGroups = bundles[bundleIndex].groups.map(i => i); // for sparse array
      updatedGroups[groupIndex] = { ...group, modules: updatedModules };

      const updatedBundles = replaceBundleGroups(bundles, bundleIndex, updatedGroups);

      state.bundles = updatedBundles;
      mutateGroups(state, updatedBundles);
    },
    resetModules: state => ({
      ...modulesInitialState,
      groups: state.groups,
    }),
  },
});

export default modulesSlice.reducer;

// ACTIONS

export const actions = {
  ... modulesSlice.actions,
  getModulesRequest: createAction<ModulesActioinRequestPayload>('getModulesRequest'),
};

// SELECTORS

export const selectModulesLoadingStatus = (state: RootState): boolean =>
  state.modules.status === SAGA_STATUS.PENDING;

export const selectModulesInfo = (state: RootState): ModulesState['info'] => state.modules.info;

export const selectModulesSections = (state: RootState): ModulesState['sections'] => state.modules.sections;

export const selectModulesBundles = (state: RootState): ModulesState['bundles'] => {
  const { bundles } = state.modules;

  return bundles && bundles.length ? bundles : undefined;
};

export const selectModulesError = (state: RootState): string => state.modules.error;

export const selectTextCollection = (state: RootState): TextCollectionType[] =>
  state.modules.textCollectionMetadata;

export const selectIsTextCollectionModalIsOpen = (state: RootState): boolean =>
  state.modules.isTextCollectionModalOpen;
