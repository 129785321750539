import React, { forwardRef } from 'react';

type AccessibleDivProps = React.HTMLAttributes<HTMLDivElement> & {
  role?: string;
};

const AccessibleDiv = forwardRef<HTMLDivElement, AccessibleDivProps>(({
  children,
  role = 'button',
  ...props
}, ref) => {
  return (
    <div
      ref={ref}
      role={role}
      {...props}
    >
      {children}
    </div>
  );
});

AccessibleDiv.displayName = 'AccessibleDiv';

export default AccessibleDiv;
