import React from 'react';

export const ErrorCard: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className="card" >
      <div className="card-body bg-danger text-white rounded" >{props.children}</div>
    </div>
  );
};

export const SuccessCard: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className="card" >
      <div className="card-body bg-success text-white rounded" >{props.children}</div>
    </div>
  );
};

export const WarningCard: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className="card" >
      <div className="card-body bg-warning text-black rounded" >{props.children}</div>
    </div>
  );
};
