import { ModulesBundle, ModulesBundleModule, ModulesSection, TextCollection } from 'store/modules/types';
import { waitForAllNestedPromises } from 'utils/waitForAllNestedPromises';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader, getRendition } from './index';

type GetModulesDataPayload = {
  jobZeroId: string;
  moduleBundleId: string;
};

export type GetModulesDataResponse = {
  jobZero: {
    id: string;
    name: string;
    number: string;
    resourceType: string;
  };
  moduleBundle: {
    id: string;
    name: string;
    number: string;
    product: string[];
  };
  bundles: (Omit<ModulesBundle, 'modules' | 'index'> & {
    modules: Omit<ModulesBundleModule, 'index' | 'selected'>[];
  })[];
  sections: ModulesSection[];
  textCollectionMetadata: TextCollection[];
};

type Bundles = GetModulesDataResponse['bundles'];

async function getRenditionsForModules(bundles: Bundles): Promise<Bundles> {
  const defaultValue = '';

  return waitForAllNestedPromises(
    bundles.map(
      (bundle) => {
        bundle.modules = bundle.modules.map(
          module => ({
            ...module,
            rendition: getRendition(module.id) as unknown as string, // type hack for waitForAllNestedPromises
          }),
        );

        return bundle;
      },
    ),
    defaultValue,
  );
}

export const getModulesData = async (
  sessionId: string,
  payload: GetModulesDataPayload,
): Promise<GetModulesDataResponse> => {
  try {
    const { data } = await apiInstance.post<GetModulesDataResponse>(
      API.GET_MODULES_FROM_VEEVA,
      payload,
      { headers: getAuthorizationHeader(sessionId) },
    );

    data.bundles = await getRenditionsForModules(data.bundles);

    return data;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
};
