import React from 'react';
import AccessibleDiv from './AccessibleDiv/AccessibleDiv';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <AccessibleDiv
        className="spinner-grow"
        style={{ width: '5rem', height: '5rem' }}
        role="output"
      >
        <span className="visually-hidden">Loading...</span>
      </AccessibleDiv>
    </div>
  );
};

export default LoadingSpinner;
