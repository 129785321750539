import { TextCollection } from 'store/modules/types';

type SelectedTextCollectionSessionStoreData = { location: string; textCollectionId: number };

enum SessionStorageKeys {
  SELECTED_TEXT_COLLECTIONS = 'selectedTextCollection',
}

export const getSelectedTextCollectionData = (): SelectedTextCollectionSessionStoreData[] => {
  const sessionData = sessionStorage.getItem(SessionStorageKeys.SELECTED_TEXT_COLLECTIONS) ?? '';
  const data: SelectedTextCollectionSessionStoreData[] = sessionData.length ? JSON.parse(sessionData) : [];

  return data;
};

export const saveSelectedTextCollectionSessionStorage = (
  isTextCollections: boolean,
  selectedLocalTextCollection: TextCollection | undefined,
): void => {
  const data = getSelectedTextCollectionData();
  const locationPath = window.location.search.substring(1);
  const textCollectionId = isTextCollections && selectedLocalTextCollection
    ? selectedLocalTextCollection.id
    : -1;

  sessionStorage.setItem(
    SessionStorageKeys.SELECTED_TEXT_COLLECTIONS,
    JSON.stringify([...data, { location: locationPath, textCollectionId }]),
  );
};

export const getSelectedTextCollectionFromSessionStorage = (textCollectionId: number): number | undefined => {
  const data = getSelectedTextCollectionData();

  return data?.find(
    item => item.textCollectionId === textCollectionId,
  )?.textCollectionId ?? undefined;
};

export const isSkipModalByNonTextCollection = (): boolean => {
  const data = getSelectedTextCollectionData();
  const locationPath = window.location.search.substring(1);
  const textCollectionId = data.find(item => item.location === locationPath)?.textCollectionId;

  return textCollectionId === -1;
};
