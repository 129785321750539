// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccordionPanel_panel__y2nTS {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  color: #212529;
  font-family: Helvetica Neue, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.AccordionPanel_panel__y2nTS.AccordionPanel_active__2pOR9 {
  border-bottom: 0;
}
.AccordionPanel_panel__y2nTS:hover {
  background: #e9ecef;
}
.AccordionPanel_panel__y2nTS svg {
  width: 20px;
  height: 20px;
}
.AccordionPanel_panel__y2nTS .AccordionPanel_label__1ddrs {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/AccordionPanel.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,mBAAA;EACA,gCAAA;EACA,eAAA;EAEA,cAAA;EACA,sDAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;AAME;EACE,OAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAJJ","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.panel {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 16px 20px;\n  gap: 8px;\n  align-self: stretch;\n  border-bottom: 1px solid $borderColor;\n  cursor: pointer;\n\n  color: #212529;\n  font-family: Helvetica Neue, Roboto, Arial, sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%;\n\n  &.active {\n    border-bottom: 0;\n  }\n\n  &:hover {\n    background: #e9ecef;\n  }\n\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n\n  .label {\n    flex: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `AccordionPanel_panel__y2nTS`,
	"active": `AccordionPanel_active__2pOR9`,
	"label": `AccordionPanel_label__1ddrs`
};
export default ___CSS_LOADER_EXPORT___;
