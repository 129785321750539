import { put, call, takeLatest } from 'redux-saga/effects';
import { getUserInfoFromVeeva } from 'api/getUserInfoFromVeeva';
import { UsersData } from 'types';
import { signInFailure, signInSuccess } from './authSlice';
import {
  getUserDataError,
  getUserDataRequest,
  getUserDataStart,
  getUserDataSuccess,
} from './userSlice';
import type { SagaIterator } from 'redux-saga';

export function* userSaga(): SagaIterator {
  try {
    yield put(getUserDataStart());
    const data: UsersData = yield call(getUserInfoFromVeeva);

    const {
      firstName,
      lastName,
      userName,
      userEmail,
      usersContacts,
    } = data;

    yield put(
      getUserDataSuccess({
        firstName,
        lastName,
        userName,
        userEmail,
        usersContacts,
      }),
    );
    yield put(signInSuccess());
  } catch (err) {
    yield put(getUserDataError(err instanceof Error ? err.message : JSON.stringify(err)));
    yield put(signInFailure());
  }
}

export default [takeLatest(getUserDataRequest.type, userSaga)];
