import { PreviewOptionName } from './PreviewOptionName';
import { ProjectType } from './ProjectType';

type VerifyType<K extends keyof T, T> = Pick<T, K> extends T ? T : never;

enum ProjectOption {
  DEFAULT_PREVIEW_OPTION = 'defaultPreviewOption',
}

type ProjectOptionType = VerifyType<ProjectOption, {
  [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName;
}>;

export const ProjectsConfig: Record<ProjectType, ProjectOptionType> = {
  [ProjectType.BRAND_ALERT_MEDSCAPE]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
  },
  [ProjectType.CARD]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
  },
  [ProjectType.EMAIL]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
  },
  [ProjectType.MOBILE_ALERT]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
  },
  [ProjectType.MOBILE_ALERT_DOXIMITY]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
  },
  [ProjectType.MOBILE_ALERT_EPOCRATES]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
  },
  [ProjectType.IPAD]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.IPAD,
  },
  [ProjectType.DISPLAY_PANEL]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.IPAD,
  },
  [ProjectType.UNIVERSAL_ARTBOARD]: {
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
  },
};
