import React from 'react';
import styles from './Switcher.module.scss';

type Props = {
  lable: string;
  onChange: (e: boolean) => void;
  prefix?: string;
};

const Switcher: React.FC<Props> = ({ lable, onChange, prefix }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (): void => {
    const value = !checked;
    setChecked(value);
    onChange(value);
  };

  return (
    <div className={styles.switcherWrapper}>
      <label className={styles.switcher} htmlFor={prefix ?? '' + 'switcher-input-id'}> {/*NO-SONAR */}
        <input
          type="checkbox"
          name="switcher-input"
          id={prefix ?? '' + 'switcher-input-id'}
          checked={checked}
          onChange={handleChange}
        />
        <span className={styles.switcherSlider} />
      </label>
      <span className={styles.switcherTextLabel}>{lable}</span>
    </div>
  );
};

export default Switcher;
