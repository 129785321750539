import classNames from 'classnames';
import React from 'react';
import variables from 'styles/_variables.module.scss';
import DownAngleArrow from 'assets/images/DownAngleArrow';
import RightAngleArrow from 'assets/images/RightAngleArrow';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Button from 'components/Button/Button';
import { CLEAR_ZONE_BUTTON } from 'const';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';

import styles from './AccordionPanel.module.scss';

type Props = React.PropsWithChildren<{
  sectionId: string;
  isActive: boolean;
  isClearButtonShown: boolean;
  label: string;
  onClick: () => void;
  onClearButtonClick: () => void;
}>;

const AccordionPanel = React.forwardRef<HTMLDivElement, Props>(({
  label,
  sectionId,
  isActive,
  isClearButtonShown,
  children,
  onClick,
  onClearButtonClick,
}, ref) => {
  const ToggleIcon = isActive ? DownAngleArrow : RightAngleArrow;

  const onMouseOver = (): void => {
    eventEmitter.emit(EVENTS.FOCUS_SECTION_RIGHT, sectionId);
  };
  const onMouseOut = (): void => {
    eventEmitter.emit(EVENTS.FOCUS_SECTION_RIGHT, '');
  };

  const handleClearButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    event?.stopPropagation();
    onClearButtonClick();
  };

  return (
    <AccessibleDiv
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      ref={ref}
    >
      <AccessibleDiv
        className={classNames(
          styles.panel,
          {
            [styles.active]: isActive,
          },
        )}
        onClick={onClick}
      >
        <ToggleIcon />
        <span className={styles.label}>{label}</span>
        {isClearButtonShown && (
          <Button
            type='button'
            isDisabled={false}
            label={CLEAR_ZONE_BUTTON}
            styleOverride={{
              backgroundColor: variables.clearSectionButtonColor,
              marginBottom: 0,
              padding: '0 5px',
              fontSize: variables.buttonFontSize,
              borderRadius: variables.buttonBorderRadius,
            }}
            onClick={handleClearButtonClick}
          />
        )}
      </AccessibleDiv>
      {isActive && children}
    </AccessibleDiv>
  );
});

AccordionPanel.displayName = 'AccordionPanel';

export default AccordionPanel;
