import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import LayoutPreview from 'components/LayoutPreview';
import Switcher from 'components/Switcher/Switcher';
import { ModulesSection, ModulesBundle } from 'store/modules/types';
import styles from '../Combinations.module.scss';
import { CombinationSparseArray } from '../useCombinations';
import { combinationGetModuleForSection, validateSectionForCombination } from '../utils';
import SelectedTextCollection from './SelectedTextCollection';

export const COMBINATIONS_PREVIEW_TEST_ID = 'combination-preview';

type Props = {
  sections: ModulesSection[];
  bundles: ModulesBundle[];
  index: number;
  combination: CombinationSparseArray | undefined;
  onRemove: () => void;
};

const CombinationPreview: React.FC<Props> = (props) => {
  const {
    sections,
    bundles,
    index,
    combination,
    onRemove,
  } = props;
  const [assetsVisible, setAssetsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [combination]);

  if (!combination) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={styles.container__middle}
      data-testid={COMBINATIONS_PREVIEW_TEST_ID}
    >

      <div className={styles.container__middle__header}>
        <div>
          <span>Combination #{index + 1}</span>
          <SelectedTextCollection />
          <div className='mt-2'>
            <Switcher onChange={setAssetsVisible} lable={'Show Asset ID'} />
          </div>
        </div>

        <Button
          type="button"
          isDisabled={false}
          intent="danger"
          label="Remove Combination"
          onClick={onRemove}
        />
      </div>

      <div className={styles.container__middle__modules}>
        {sections.map((item, idx) => {
          if (!validateSectionForCombination(item)) {
            return null;
          }

          const [layouts] = combinationGetModuleForSection(bundles, combination, idx) ?? [];
          if (!layouts) {
            return null;
          }

          return layouts.map(layout => (
            <div key={`${item.section.id}-${layout.index}`} className={styles.container__module}>
              <LayoutPreview
                showNumbers={assetsVisible}
                assetNumber={layout.number}
                documentId={layout.id}
                alt={layout.classification}
              />
            </div>
          ));
        })}
      </div>

    </div>
  );
};

export default CombinationPreview;
