import { ModulesBundle, ModulesBundleModule, ModulesSection, TextCollectionType } from 'store/modules/types';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

type GetModulesDataPayload = {
  jobZeroId: string;
  moduleBundleId: string;
};

export type GetModulesDataResponse = {
  jobZero: {
    id: string;
    name: string;
    number: string;
    resourceType: string;
  };
  moduleBundle: {
    id: string;
    name: string;
    number: string;
    product: string[];
  };
  bundles: (Omit<ModulesBundle, 'modules' | 'index'> & {
    modules: Omit<ModulesBundleModule, 'index' | 'selected'>[];
  })[];
  sections: ModulesSection[];
  textCollectionMetadata: TextCollectionType[];
};

export const getModulesData = async (
  sessionId: string,
  payload: GetModulesDataPayload,
): Promise<GetModulesDataResponse> => {
  try {
    const { data } = await apiInstance.post<GetModulesDataResponse>(
      API.GET_MODULES_FROM_VEEVA,
      payload,
      { headers: getAuthorizationHeader(sessionId) },
    );

    return data;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
};
