import classNames from 'classnames';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectSelectedTextCollection,
  selectTextCollection,
} from 'store/modules/slice';
import styles from '../Combinations.module.scss';
import ChangeSelectionButton from './ChangeSelectionButton';

const SelectedTextCollection: React.FC = () => {
  const selectedTextCollection = useAppSelector(selectSelectedTextCollection);
  const textCollections = useAppSelector(selectTextCollection);

  if (!selectedTextCollection) {
    return null;
  }

  return (
    <div className={classNames(styles.selectedTextCollectionClassName, 'mt-2')}>
      <div className='fw-normal'>You are using the text collection</div>
      <div>{selectedTextCollection.name}</div>
      {textCollections.length > 1 ? <ChangeSelectionButton /> : null}
    </div>
  );
};

export default SelectedTextCollection;
