// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_linkWrapper__eq7Az {
  font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.styles_linkWrapper__eq7Az .styles_label__8\\+e5p {
  font-weight: 500;
  margin-right: 5px;
}
.styles_linkWrapper__eq7Az .styles_link__\\+zlMY {
  color: #6c757d;
  font-weight: 400;
  text-decoration-line: underline;
  position: relative;
  margin-right: 44px;
}
.styles_linkWrapper__eq7Az .styles_link__\\+zlMY svg {
  margin-left: 2px;
  fill: #6c757d;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
}
.styles_linkWrapper__eq7Az.styles_moduleBundleHeader__1e0Gq {
  color: #fff;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  padding-left: 1rem;
}
.styles_linkWrapper__eq7Az.styles_moduleBundleHeader__1e0Gq .styles_label__8\\+e5p {
  opacity: 0.65;
}
.styles_linkWrapper__eq7Az.styles_moduleBundleHeader__1e0Gq .styles_link__\\+zlMY {
  color: #fff;
}
.styles_linkWrapper__eq7Az.styles_moduleBundleHeader__1e0Gq .styles_link__\\+zlMY svg {
  fill: #fff;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ModuleBundleDocumentLink/styles.module.scss"],"names":[],"mappings":"AACA;EACE,wDAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;AAAF;AAEE;EACE,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,cAAA;EACA,gBAAA;EACA,+BAAA;EACA,kBAAA;EACA,kBAAA;AADJ;AAGI;EACE,gBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AADN;AAKE;EACE,WAAA;EACA,+CAAA;EACA,kBAAA;AAHJ;AAKI;EACE,aAAA;AAHN;AAMI;EACE,WAAA;AAJN;AAMM;EACE,UAAA;EACA,WAAA;EACA,YAAA;AAJR","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.linkWrapper {\n  font-family: \"Helvetica Neue\", Roboto, Arial, sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%;\n  color: #6c757d;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n\n  .label {\n    font-weight: 500;\n    margin-right: 5px;\n  }\n\n  .link {\n    color: #6c757d;\n    font-weight: 400;\n    text-decoration-line: underline;\n    position: relative;\n    margin-right: 44px;\n\n    svg {\n      margin-left: 2px;\n      fill: #6c757d;\n      width: 15px;\n      height: 15px;\n      position: absolute;\n      bottom: 0;\n    }\n  }\n\n  &.moduleBundleHeader {\n    color: #fff;\n    border-left: 2px solid rgba(255, 255, 255, 0.20);\n    padding-left: 1rem;\n\n    .label {\n      opacity: 0.65;\n    }\n\n    .link {\n      color: #fff;\n\n      svg {\n        fill: #fff;\n        width: 16px;\n        height: 16px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWrapper": `styles_linkWrapper__eq7Az`,
	"label": `styles_label__8+e5p`,
	"link": `styles_link__+zlMY`,
	"moduleBundleHeader": `styles_moduleBundleHeader__1e0Gq`
};
export default ___CSS_LOADER_EXPORT___;
