import { replaceGroupsInBundle } from 'store/modules/helpers';
import { ModulesBundle, ModulesBundleModule } from 'store/modules/types';

export type ModulesBundleWithSelection = ModulesBundle & {
  selectedCount: number;
  availableCount: number;
  clusterSelectionRequired: boolean;
};

export function validateSelection(bundles: ModulesBundleWithSelection[]): boolean {
  return bundles.some((bundle): boolean => {
    const moduleSelected = bundle.modules.some(({ selected }) => selected);
    const groupSelected = bundle.groups.some(({ selected }) => selected);

    return (moduleSelected || groupSelected) && !bundle.clusterSelectionRequired;
  });
}

export function validateClusterSelection(bundles: ModulesBundleWithSelection[]): boolean {
  return bundles.every(bundle => !bundle.clusterSelectionRequired);
}

function createBundleWithSelection(
  bundle: ModulesBundle,
): ModulesBundleWithSelection {
  const { modules, groups } = bundle;

  const availableModules = modules.filter(({ disabled }) => !disabled).length;
  const selectedModules = modules.filter(({ selected }) => selected).length;
  const availableGroups = groups.filter(group => group).length;
  const selectedGroups = groups.filter(group => group.selected).length;

  return {
    clusterSelectionRequired: false,
    ...bundle,
    modules: modules.map(_module => ({ ..._module })),
    groups: groups.map(group => ({ ...group })),
    selectedCount: selectedModules + selectedGroups,
    availableCount: availableModules + availableGroups,
  };
}

export function convertBundleWithSelectionToBundle(
  bundle: ModulesBundleWithSelection,
): ModulesBundle {
  const {
    modules,
    groups,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedCount,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    availableCount,
    ...other
  } = bundle;

  return {
    ...other,
    modules: modules.map(_module => ({ ..._module })),
    groups: groups.map(group => ({ ...group })),
  };
}

function replaceBundle(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  newBundle: ModulesBundle,
): ModulesBundleWithSelection[] {
  const newState = [...bundles];
  newState[bundleIndex] = createBundleWithSelection(newBundle);

  return newState;
}

function replaceModuleInBundle(
  bundle: ModulesBundleWithSelection,
  moduleIndex: number,
  _module: ModulesBundleModule,
): ModulesBundleWithSelection {
  const modules = [...bundle.modules];
  modules[moduleIndex] = _module;

  return { ...bundle, modules };
}

export function updateBundles(
  current: ModulesBundleWithSelection[],
  updated: ModulesBundle[],
): ModulesBundleWithSelection[] {
  return updated.map((bundle) => {
    const currentModules = current[bundle.index]?.modules || bundle.modules;
    const modules = bundle.modules.map((_module) => {
      return {
        ..._module,
        selected: Boolean(currentModules?.[_module.index]?.selected),
        disabled: Boolean(currentModules?.[_module.index]?.disabled),
      };
    });

    const currentGroups = current[bundle.index]?.groups || bundle.groups;
    const groups = bundle.groups.map((group) => {
      return {
        ...group,
        selected: Boolean(currentGroups?.[group.index]?.selected),
      };
    });

    return createBundleWithSelection({
      ...bundle,
      modules,
      groups,
    });
  });
}

function resetRequiredClusters(bundles: ModulesBundleWithSelection[], bundleIndex: number): void {
  if (!bundles[bundleIndex].clusterSelectionRequired) {
    return;
  }
  bundles.forEach((bundle) => {
    bundle.clusterSelectionRequired = false;
  });
}

function getUpdatedBundles(
  bundles: ModulesBundleWithSelection[],
  moduleWithSelection: ModulesBundleModule,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  let updatedBundles = bundles;

  bundles.forEach((bundle) => {
    let updatedBundle = bundle;

    bundle.modules.forEach((item) => {
      const isTarget = item === moduleWithSelection;
      const isDuplicate = !isTarget && item.id === moduleWithSelection.id;

      if (isTarget || isDuplicate) {
        updatedBundle = replaceModuleInBundle(updatedBundle, item.index, {
          ...item,
          selected: isTarget ? isSelected : false,
          disabled: isTarget ? false : isSelected,
        });
      }
    });

    if (updatedBundle !== bundle) {
      updatedBundles = replaceBundle(updatedBundles, bundle.index, updatedBundle);
    }
  });

  return updatedBundles;
}
export function setModuleSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  moduleIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const moduleWithSelection = bundles[bundleIndex].modules[moduleIndex];
  if (!moduleWithSelection) {
    return bundles;
  }

  resetRequiredClusters(bundles, bundleIndex);

  return getUpdatedBundles(bundles, moduleWithSelection, isSelected);
}

export function setGroupSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  groupIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const currentBundle = bundles[bundleIndex];

  resetRequiredClusters(bundles, bundleIndex);

  const group = currentBundle.groups[groupIndex];
  if (!group || group.selected === isSelected) {
    return bundles;
  }

  const updatedGroups = currentBundle.groups.map(i => i);
  updatedGroups[groupIndex] = {
    ...group,
    selected: isSelected,
  };

  return replaceBundle(
    bundles,
    bundleIndex,
    replaceGroupsInBundle(currentBundle, updatedGroups),
  );
}

export function isEntireBundleSelected(
  bundle: ModulesBundleWithSelection | undefined,
): boolean {
  if (!bundle) {
    return false;
  }
  const { modules, groups } = bundle;
  const allModulesSelected = modules.filter(({ disabled }) => !disabled).every(({ selected }) => selected);
  const allGroupsSelected = groups.every(group => group.selected);

  return allModulesSelected && allGroupsSelected;
}

export function setEntireBundleSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const bundle = bundles[bundleIndex];
  if (!bundle) {
    return bundles;
  }

  let updatedState = bundle.modules.reduce(
    (acc, _module) => acc[bundleIndex].modules[_module.index].disabled
      ? acc
      : setModuleSelection(acc, bundleIndex, _module.index, isSelected),
    bundles,
  );

  updatedState = bundle.groups.reduce(
    (acc, group) => setGroupSelection(acc, bundleIndex, group.index, isSelected),
    updatedState,
  );

  return updatedState;
}
