import React, { useEffect, useRef, useState } from 'react';
import { getRendition } from 'api';
import ClipboardText from 'components/Clipboard/ClipboardText';
import LoadingSpinner from 'components/LoadingSpinner';

import styles from './LayoutPreview.module.scss';

type Props = {
  rendition?: string;
  documentId: string;
  alt?: string;
  stringifiedClassName?: string;
  assetNumber?: string;
  showNumbers?: boolean;
};

const LayoutPreview = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    documentId,
    rendition,
    alt = '',
    stringifiedClassName = '',
    assetNumber,
    showNumbers,
  } = props;

  const [source, setSource] = useState(rendition);
  const [loading, setLoading] = useState(false);
  const loadingPromise = useRef<Promise<void>>();

  useEffect(() => {
    if (rendition) {
      return;
    }

    setLoading(true);

    const promise = getRendition(documentId).then((imageSource) => {
      // eslint-disable-next-line promise/always-return
      if (promise !== loadingPromise.current) {
        return;
      }
      setSource(imageSource);
      setLoading(false);
    })
      .catch((error) => {
        setSource('');
        setLoading(false);
        console.error(error); // eslint-disable-line no-console
      });

    loadingPromise.current = promise;
  }, [documentId, rendition]);

  if (loading) {
    return (
      <div ref={ref} className="d-flex flex-column align-items-center my-5">
        <LoadingSpinner />
      </div>
    );
  } else if (!source) {
    return null;
  }

  return (
    <div className={`w-100 lh-zero ${stringifiedClassName} ${styles.layoutPreviewWrapper}`} ref={ref}>
      <img className="w-100" src={source} alt={alt} />
      {assetNumber && showNumbers && (
        <div className={styles.clipboardWrapper}>
          <ClipboardText text={assetNumber} />
        </div>
      )}
    </div>
  );
});

LayoutPreview.displayName = 'LayoutPreview';

export default LayoutPreview;
