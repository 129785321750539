import classNames from 'classnames';
import React from 'react';
import { RadioButton } from 'components/RadioButton/RadioButton';
import styles from './TextCollectionModal.module.scss';

type TextCollection = {
  id: number;
  name: string;
  title: string;
  assetNumber: string;
};

type Props = {
  textCollections: TextCollection[];
  onChangeTable: (value: TextCollection) => void;
  selectedTextCollection: TextCollection | undefined;
};

export const TextCollectionsTable: React.FC<Props> = ({
  textCollections,
  onChangeTable,
  selectedTextCollection,
}) => {
  return (
    <div className={classNames(
      styles.textCollectionsTable__wrapper,
      {
        [styles.textCollectionsTable__wrapper__scroll]: textCollections?.length > 3,
      },
    )}>
      <table className={styles.textCollectionsTable}>
        <thead>
          <tr>
            <th />
            <th>Text Collection Name</th>
            <th className={styles.textCollectionsTable__assetsNumber}>Asset ID</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {!!textCollections?.length && textCollections.map((textCollection) => {
            return (
              <tr className={styles.textCollectionsTable__row} key={textCollection.id}>
                <td>
                  <div className={styles.textCollectionsTable__radio}>
                    <RadioButton
                      onChange={(): void=>onChangeTable(textCollection)}
                      value={textCollection.assetNumber}
                      group='textCollectionRadioGroup' id={textCollection.id}
                      checked={selectedTextCollection?.id === textCollection.id}
                    />
                  </div>
                </td>
                <td><span>{textCollection.name}</span></td>
                <td><span>{textCollection.assetNumber}</span></td>
                <td><span>{textCollection.title}</span></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
