// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClipboardText_ClipboardText__t9VnU {
  color: #00504A;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
.ClipboardText_ClipboardTextWrapper__S1HQ8 {
  display: flex;
  align-items: center;
}
.ClipboardText_ClipboardTextButton__VUcPH {
  display: inline-block;
}
.ClipboardText_ClipboardTextButton__VUcPH button {
  display: flex;
  align-items: center;
  margin: 0;
  border: 0;
  padding: 4px;
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Clipboard/ClipboardText.module.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACD;AAAC;EACC,aAAA;EACA,mBAAA;AAEF;AAAC;EACC,qBAAA;AAEF;AADE;EACC,aAAA;EACA,mBAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAGH","sourcesContent":[".ClipboardText {\n\tcolor: #00504A;\n\tfont-size: 14px;\n\tline-height: 14px;\n\tfont-weight: 500;\n\t&Wrapper {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t}\n\t&Button {\n\t\tdisplay: inline-block;\n\t\tbutton {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tmargin: 0;\n\t\t\tborder: 0;\n\t\t\tpadding: 4px;\n\t\t\twidth: 24px;\n\t\t\theight: 24px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ClipboardText": `ClipboardText_ClipboardText__t9VnU`,
	"ClipboardTextWrapper": `ClipboardText_ClipboardTextWrapper__S1HQ8`,
	"ClipboardTextButton": `ClipboardText_ClipboardTextButton__VUcPH`
};
export default ___CSS_LOADER_EXPORT___;
