import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AUTH_TYPE } from 'api/constants';
import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import {
  selectAuthError,
  selectAuthType,
  selectIsAuthenticated,
  selectIsAuthInProgress,
  signInDiscoveryRequest,
  signInRequest,
} from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppRoute } from 'types';
import { authenticate, getSessionId } from 'utils';
import styles from './Home.module.scss';

const Home: React.FC = () => {
  const sessionId = getSessionId();
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authType = useAppSelector(selectAuthType);
  const isAuthInProgress = useAppSelector(selectIsAuthInProgress);
  const authError = useAppSelector(selectAuthError);

  const onAuthDiscoverySubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    dispatch(signInDiscoveryRequest(username));
  };

  const onLoginHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    const payload = {
      authType: AUTH_TYPE.PASSWORD,
      username,
      password,
    } as const;
    dispatch(signInRequest(payload));
  };

  useEffect(() => {
    if (!sessionId) {
      if (code) {
        navigate(AppRoute.HOME, { replace: true });
        const payload = {
          authType: AUTH_TYPE.SSO,
          code,
        } as const;
        dispatch(signInRequest(payload));
      }

      if (!code && authType && authType === AUTH_TYPE.SSO) {
        authenticate();
      }
    }
  }, [sessionId, authError, authType]);

  let content = <div />;

  if (!sessionId && !code) {
    content = (
      <form
        className={`${styles.form} border rounded p-3`}
        onSubmit={onAuthDiscoverySubmitHandler}
      >
        <div className="mb-3">
          <label htmlFor="user" className="form-label">
            Enter email registered in Veeva
          </label>
          <input
            className="form-control"
            id="email"
            type="email"
            value={username}
            onChange={(e): void => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <Button type="submit" isDisabled={false} label="Submit" />
      </form>
    );

    if (authType && authType === AUTH_TYPE.PASSWORD) {
      content = (
        <form
          className={`${styles.form} border rounded p-3`}
          onSubmit={onLoginHandler}
        >
          <div className="mb-3">
            <label htmlFor="user" className="form-label">
              Enter email
            </label>
            <input
              className="form-control"
              id="email"
              type="email"
              value={username}
              onChange={(e): void => {
                setUsername(e.target.value);
              }}
            />
            <label htmlFor="password" className="form-label">
              Enter password
            </label>
            <input
              className="form-control"
              id="password"
              type="password"
              value={password}
              onChange={(e): void => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {/* eslint-disable-next-line multiline-ternary */}
          {authError ? (
            <div>Please check your credentials and try again</div>
          ) : null}
          <Button type="submit" isDisabled={false} label="Log In" />
        </form>
      );
    }
  }

  if (isAuthenticated) {
    return <Navigate to={AppRoute.FILTERS} />;
  }

  return (
    <>
      <NavBar />
      <main
        className='d-flex flex-column align-items-center justify-content-center'
      >
        {isAuthInProgress ? <LoadingSpinner /> : content}
      </main>
    </>
  );
};

export default Home;
