import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Channels from 'pages/Channels';
import IframePlaceholder from 'pages/Channels/IframePlaceholder';
import Combinations from 'pages/Combinations/Combinations';
import Filters from 'pages/Filters/Filters';
import Home from 'pages/Home/Home';
import Modules from 'pages/Modules/Modules';
import 'react-toastify/dist/ReactToastify.css';
import Simulation from 'pages/Simulation';
import store from 'store';
import { AppRoute } from 'types';
import AuthProvider from './pages/AuthProvider';

declare global {
  interface Window {
    FEATURE_TEXT_COLLECTION_MODAL: boolean;
  }
}

window.FEATURE_TEXT_COLLECTION_MODAL = false;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path={AppRoute.HOME} element={<Home />}/>
            <Route path={AppRoute.FILTERS} element={<Filters />}/>
            <Route path={AppRoute.MODULES} element={<Modules />}/>
            <Route path={AppRoute.COMBINATIONS} element={<Combinations />} />
            <Route path={AppRoute.CHANNELS} element={<Channels />} />
            <Route path={AppRoute.SIMULATION} element={<Simulation />} />
            <Route path={AppRoute.IFRAME_PLACEHOLDER} element={<IframePlaceholder />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
