// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextCollectionModal_textCollectionsTable__jdqy- {
  width: 100%;
}
.TextCollectionModal_textCollectionsTable__assetsNumber__6zemU {
  width: 150px;
}
.TextCollectionModal_textCollectionsTable__wrapper__I1sHy {
  max-height: 200px;
  overflow-y: auto;
}
.TextCollectionModal_textCollectionsTable__jdqy- th {
  background: #F0F0F0;
  padding: 10px 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.TextCollectionModal_textCollectionsTable__jdqy- td:not(:first-child) {
  padding: 0 12px;
}
.TextCollectionModal_textCollectionsTable__jdqy- tr {
  border-bottom: 1px solid #F0F0F0;
}
.TextCollectionModal_textCollectionsTable__jdqy- tr span {
  font-size: 14px;
  line-height: 22px;
}
.TextCollectionModal_textCollectionsTable__radio__ocp4c {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Modules/components/TextCollectionsModal/TextCollectionModal.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;AAGI;EACI,YAAA;AADR;AAII;EACI,iBAAA;EACA,gBAAA;AAFR;AAKI;EACI,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;AAHR;AAMI;EACI,eAAA;AAJR;AAOI;EACI,gCAAA;AALR;AAOQ;EACI,eAAA;EACA,iBAAA;AALZ;AASI;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAPR","sourcesContent":["@import 'styles/_colors.module';\n\n.textCollectionsTable {\n    width: 100%;\n\n    &__assetsNumber {\n        width: 150px;\n    }\n\n    &__wrapper {\n        max-height: 200px;\n        overflow-y: auto;\n    }\n\n    th {\n        background: map-get($gray , 50);\n        padding: 10px 8px;\n        position: sticky;\n        top: 0;\n        z-index: 1;\n    }\n\n    td:not(:first-child) {\n        padding: 0 12px;\n    }\n\n    tr {\n        border-bottom: 1px solid map-get($gray, 50);\n\n        span {\n            font-size: 14px;\n            line-height: 22px;\n        }\n    }\n\n    &__radio {\n        padding: 15px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textCollectionsTable": `TextCollectionModal_textCollectionsTable__jdqy-`,
	"textCollectionsTable__assetsNumber": `TextCollectionModal_textCollectionsTable__assetsNumber__6zemU`,
	"textCollectionsTable__wrapper": `TextCollectionModal_textCollectionsTable__wrapper__I1sHy`,
	"textCollectionsTable__radio": `TextCollectionModal_textCollectionsTable__radio__ocp4c`
};
export default ___CSS_LOADER_EXPORT___;
