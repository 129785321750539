import React from 'react';
import Tab from 'components/Tab';
import { UseBundlesReturnType } from '../hooks/useBundles';

export const moduleTypeTestId = 'moduleTypeTestId';

export const BundlesTabs: React.FC<Partial<UseBundlesReturnType>> = ({
  bundles,
  activeBundleIndex,
  setActiveBundleIndex,
}) => {

  return (<>
    {bundles?.map((bundle, index) =>
      (<Tab
        key={bundle.index}
        count={bundle.selectedCount}
        index={index}
        isActive={index === activeBundleIndex}
        label={bundle.type}
        testId={moduleTypeTestId}
        isShowWarning={bundle.clusterSelectionRequired}
        onClick={(): void => setActiveBundleIndex?.(index)}
      />),
    )}</>);
};
