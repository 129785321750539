import React from 'react';
import { TextCollectionModal } from 'pages/Modules/components/TextCollectionsModal/TextCollectionsModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actions, selectIsTextCollectionModalIsOpen } from 'store/modules/slice';

const ChangeSelectionButton: React.FC = () => {
  const dispatchToRedux = useAppDispatch();
  const isTextCollectionOpen = useAppSelector(selectIsTextCollectionModalIsOpen);

  return (
    <>
      <button className="btn btn-link btn-sm px-0 pt-0 btn-sm"
        onClick={(): void => { dispatchToRedux(actions.toggleTextCollectionModal(true)); }}
      >
        Change selection
      </button>
      <TextCollectionModal isOpen={isTextCollectionOpen} />
    </>
  );
};

export default ChangeSelectionButton;
