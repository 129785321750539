import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import { ErrorCard, WarningCard } from 'pages/Bootstrap/Card';
import { buildCombinations } from 'pages/Combinations/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectModulesSections,
  actions,
  selectTextCollection,
  selectSelectedTextCollection,
} from 'store/modules/slice';
import { isSkipModalByNonTextCollection } from 'utils/selectedTextCollectionSessionStorage';
import { BundlesTabs } from '../components/BandleTabs';
import BundleList from '../components/BundleList';
import BundlesNavigation from '../components/BundlesNavigation';
import { BundlesContext } from '../context/bundles';
import { convertBundleWithSelectionToBundle, validateClusterSelection, validateSelection } from '../hooks/helpers';
import useBundles from '../hooks/useBundles';
import { getPopulatedClusters, recomputeClusters } from '../hooks/useClusters';
import styles from '../Modules.module.scss';
export { moduleTypeTestId } from '../components/BandleTabs';

type Props = {
  modal?: {
    onFormSubmit: () => void;
  };
};

const BundleForm: React.FC<Props> = ({ modal }) => {
  const navigate = useNavigate();
  const dispatchToRedux = useAppDispatch();
  const dataSections = useAppSelector(selectModulesSections);
  const textCollections = useAppSelector(selectTextCollection);
  const selectedTextCollection = useAppSelector(selectSelectedTextCollection);
  const [isSubmitAttempt, setIsSubmitAttempt] = useState(false);
  const [displaySelectPromotionalMessage, setDisplaySelectPromotionalMessage] = useState(false);

  const {
    bundles,
    bundlesDispatch,
    selectedCountModulesTotal,
    selectionIsValid,
    activeBundleIndex,
    bundlesIsActive,
    setActiveBundleIndex,
    setNextBundleActive,
    setPrevBundleActive,
  } = useBundles();

  const activeBundle = bundles[activeBundleIndex];
  if (!activeBundle || !bundlesIsActive) {
    // eslint-disable-next-line no-console
    console.warn('activeBundle or bundlesIsActive is empty or false');
  }

  const bundlesContext = useMemo(() => ({
    bundles,
    bundlesDispatch,
  }), [bundles, bundlesDispatch]);

  const resultedCombinationsNumber = useMemo(
    () => (dataSections && bundles.length) ? buildCombinations(bundles, dataSections).length : 0,
    [bundles, dataSections],
  );

  const isBundleListEmpty = bundles.length === 0;

  const userMustSelectAtLeastOneCluster = textCollections.length > 0
    && !isBundleListEmpty
    && getPopulatedClusters(bundles).length === 0;

  useEffect(() => {
    if (!userMustSelectAtLeastOneCluster) {
      setDisplaySelectPromotionalMessage(userMustSelectAtLeastOneCluster);
    }
  }, [bundles, textCollections]);

  const makeRedirectToCombinations = useCallback((): void => {
    setIsSubmitAttempt(true);

    if (userMustSelectAtLeastOneCluster) {
      setDisplaySelectPromotionalMessage(true);

      return;
    }

    let updatedBundles = bundles;

    if (textCollections.length > 0) {
      updatedBundles = recomputeClusters(bundles);
    }

    dispatchToRedux(actions.updateModulesBundles(updatedBundles));

    if (!validateSelection(updatedBundles) || !validateClusterSelection(updatedBundles)) {
      return;
    }

    dispatchToRedux(
      actions.updateModulesBundlesForCombinations(
        updatedBundles.map(convertBundleWithSelectionToBundle),
      ),
    );

    if (modal) {
      // in case this button is clicked from the modal dialog of Preview page
      modal.onFormSubmit();
    } else {
      navigate('/combinations');
    }
  }, [bundles, modal]);

  const onFormSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();

    if (selectedTextCollection || modal || isSkipModalByNonTextCollection()) {
      makeRedirectToCombinations(); // we validate selections here
    } else if (textCollections.length === 1) { // if we have only one textCollection, select first one automatically
      dispatchToRedux(actions.setSelectedTextCollection(textCollections[0]));
      makeRedirectToCombinations();
    } else {
      // if there are no textCollections or more than one - let's display a modal
      dispatchToRedux(actions.toggleTextCollectionModal(true));
    }
  };

  if (!dataSections) {
    return (<div>Failed to load sections the jobZero or moduleBundle</div>);
  }

  const activeBundleLacksPlaceholder = dataSections.findIndex(
    i => i.placeholder && i.bundleIndexes.includes(activeBundleIndex),
  ) < 0;

  const userMustFillGapInClusters = bundles.some(bundle => bundle.clusterSelectionRequired);
  const displaySelectAtLEastOneModule = isSubmitAttempt && !selectionIsValid;

  return (
    <form className="d-flex pt-4" onSubmit={onFormSubmitHandler}>
      <div className={`me-5 flex-shrink-0 ${styles.tabsContainer}`}>

        <h6 className="mb-3 fw-bold">Module Types</h6>
        <ul className={`nav nav-pills flex-column flex-nowrap mb-4 ${styles.tabs}`}>
          <BundlesTabs
            bundles={bundles}
            activeBundleIndex={activeBundleIndex}
            setActiveBundleIndex={setActiveBundleIndex} />
        </ul>

        {displaySelectAtLEastOneModule && (
          <ErrorCard>Select at least one module to create combinations.</ErrorCard>
        )}

        {userMustFillGapInClusters && (
          <ErrorCard>
            A gap in promotional content has been detected. One or more zones may be missing content.
          </ErrorCard>
        )}

        {
          displaySelectPromotionalMessage
          && !displaySelectAtLEastOneModule
          && <WarningCard>Please select promotional content.</WarningCard>
        }

        <Button
          type="submit"
          isDisabled={displaySelectPromotionalMessage || userMustFillGapInClusters || isBundleListEmpty}
          label={selectionIsValid ? `Add Combinations (${resultedCombinationsNumber})` : 'Add Combinations'}
          styleOverride={{ width: '100%' }}
          className="mb-3 mt-3"
        />

        {selectionIsValid && (
          <div className={styles.possibleCombinationsMessage}>
            {`${resultedCombinationsNumber} combination(s) will be added.
            You will have the option to review the content in the next step.`}
          </div>
        )}

        {!selectionIsValid && selectedCountModulesTotal > 0 && (
          <div className={styles.selectRemainingModulesMessage}>
            Select modules for the remaining module types to create combinations.
          </div>
        )}

      </div>
      <div className={`flex-grow-1 ${styles.modulesContainer}`}>

        {activeBundleLacksPlaceholder && (
          <div className='text-danger pt-2 pb-4 px-2'>no placeholder in the template</div>
        )}

        <BundlesContext.Provider value={bundlesContext}>
          <BundleList bundleIndex={activeBundleIndex} makeRedirectToCombinations={makeRedirectToCombinations} />
        </BundlesContext.Provider>

        <BundlesNavigation
          onPrev={setPrevBundleActive}
          onNext={setNextBundleActive}
        />

      </div>
    </form>
  );
};

export default BundleForm;
