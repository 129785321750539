import React from 'react';
import { RadioButton } from 'components/RadioButton/RadioButton';
import styles from './TextCollectionModal.module.scss';

type TextCollectionType = {
  id: string;
  name: string;
  title: string;
  assetNumber: string;
};

type Props = {
  textCollections: TextCollectionType[];
  onChangeTable: (value: string) => void;
};

export const TextCollectionsTable: React.FC<Props> = ({ textCollections, onChangeTable }) => {

  return (
    <div className={styles.textCollectionsTable__wrapper}>
      <table className={styles.textCollectionsTable}>
        <thead>
          <tr>
            <th />
            <th>Text collection name</th>
            <th className={styles.textCollectionsTable__assetsNumber}>Asset ID</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {!!textCollections?.length && textCollections.map(({
            name,
            title,
            id,
            assetNumber,
          }) => {
            return (
              <tr className={styles.textCollectionsTable__row} key={id}>
                <td>
                  <div className={styles.textCollectionsTable__radio}>
                    <RadioButton
                      onChange={onChangeTable}
                      value={assetNumber}
                      group='textCollectionRadioGroup' id={id}
                    />
                  </div>
                </td>
                <td><span>{name}</span></td>
                <td><span>{assetNumber}</span></td>
                <td><span>{title}</span></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
