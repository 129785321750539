import React from 'react';
import styles from './RadioButton.module.scss';

type Props = {
  label?: string;
  value: string;
  group: string;
  id: string;
  onChange: (value: string) => void;
};

export const RadioButton: React.FC<Props> = ({
  group,
  value,
  label,
  id,
  onChange,
}) => {

  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.currentTarget.value);
  };

  return (
    <label className={styles.radioButton__wrapper}>
      <input
        onChange={onChangeHandler}
        type="radio"
        name={group}
        id={id} value={value}
        className={styles.radioButton}
      />
      {label && (<span className={styles.radioButton__label}>{label}</span>)}
    </label>
  );
};
