import classNames from 'classnames';
import React, { useImperativeHandle, useRef } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import LayoutPreview from 'components/LayoutPreview';
import type { Layout } from 'types';
import AssetIdField from '../Section/AssetIdField';
import styles from './styles.module.scss';

type ItemsRef = Record<string, HTMLDivElement | null>;

interface LayoutPreviewListProps {
  layouts: Layout[];
  onItemClick?: (layout: Layout) => void;
}

const LayoutPreviewList = React.forwardRef<ItemsRef, LayoutPreviewListProps>(({
  layouts,
  onItemClick,
}, ref) => {
  const items = useRef<ItemsRef>({});
  useImperativeHandle(ref, (): ItemsRef => items.current, []);

  return (
    <div className={styles.layoutWrapper}>
      {layouts.map(layout => (
        <AccessibleDiv
          onClick={onItemClick?.bind(this, layout)}
          className={classNames(styles.layoutContainer, 'd-flex')}
          key={layout.id}
          ref={(item): void => {
            items.current[layout.id] = item;
          }}
        >
          <div className={classNames('position-sticky', styles.sectionStickyPanel)}>
            <div className='border border-1 rounded ps-2 mt-1 shadow bg-white w-max-content'>
              <AssetIdField layout={layout}/>
            </div>
          </div>

          <LayoutPreview
            stringifiedClassName={classNames(styles.layout, { [styles.active]: layout.active })}
            documentId={layout.documentId}
          />
        </AccessibleDiv>
      ))}
    </div>
  );
});

LayoutPreviewList.displayName = 'LayoutPreviewList';

export default LayoutPreviewList;
