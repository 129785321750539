import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import { ModulesBundleModule } from 'store/modules/types';
import styles from '../Modules.module.scss';
import { BundleListItemModule } from './BundleListItemModule';

const ERROR_MIN_NUMBER = 'Select at least 2 modules to create a module group';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  modules: ModulesBundleModule[];
  onCreate: (indexes: number[]) => void;
};

export const GroupModal: React.FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    modules,
    onCreate,
  } = props;

  const [selected, setSelected] = useState<boolean[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [assetsVisible, setAssetsVisible] = useState(false);

  const onCloseHandler = (): void => {
    setAssetsVisible(false);
    onClose();
  };

  useEffect(() => {
    setSelected([]);
    setError(undefined);
  }, [modules, isOpen]);

  const createOnChangeHandler = (idx: number) => (): void => {
    setSelected((vals) => {
      const updated = [...vals];
      updated[idx] = !updated[idx];

      return updated;
    });
    setError(undefined);
  };

  const createGroupHandler = (): void => {
    const indexes = selected.flatMap((item, idx) => item ? [idx] : []);
    if (indexes.length < 2) {
      setError(ERROR_MIN_NUMBER);

      return;
    }
    onCreate(indexes);
    setAssetsVisible(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      size="xl"
      title="Create Module Group"
      toggleText={'Show Asset ID'}
      toggleHandler={setAssetsVisible}
    >

      <div className="modal-body">
        <div className={`mb-3 ${styles.moduleThumbnails}`}>
          {modules.map((module, idx) => (
            <BundleListItemModule
              key={`${module.index}-${module.layoutId}`}
              module={module}
              onChange={createOnChangeHandler(idx)}
              checked={selected[idx]}
              showNumbers={assetsVisible}
            />
          ))}
        </div>
      </div>

      <div className="modal-footer">
        {error && (
          <div className="text-danger mx-3 small">{error}</div>
        )}
        <Button
          label="Create"
          type="button"
          onClick={createGroupHandler}
        />
      </div>
    </Modal>
  );
};
