import { UsersData } from 'types';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

export async function getUserInfoFromVeeva(): Promise<UsersData> {
  try {
    const { data } = await apiInstance.post<UsersData>(
      API.GET_USER_INFO_FROM_VEEVA,
      {},
      { headers: getAuthorizationHeader() },
    );

    return data;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}
