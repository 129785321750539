// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_screens__oTO6a {
  display: flex;
  width: 34px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border: 1px solid #dee2e6;
  background: #fff;
}
.styles_screens__oTO6a .styles_screen__MoQw\\+ {
  display: flex;
  width: 24px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #212529;
  font-family: Helvetica Neue, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 2px;
  background: #e9ecef;
}
.styles_screens__oTO6a .styles_screen__MoQw\\+.styles_active__a7QO3 {
  background: #ced4da;
}`, "",{"version":3,"sources":["webpack://./src/components/NavigationScreens/styles.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,QAAA;EACA,cAAA;EAEA,yBAAA;EACA,gBAAA;AADF;AAGE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;EACA,sDAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,mBAAA;AAHJ;AAKI;EACE,mBAAA;AAHN","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.screens {\n  display: flex;\n  width: 34px;\n  padding: 4px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  flex-shrink: 0;\n\n  border: 1px solid $borderColor;\n  background: #fff;\n\n  .screen {\n    display: flex;\n    width: 24px;\n    height: 32px;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    user-select: none;\n\n    color: #212529;\n    font-family: Helvetica Neue, Roboto, Arial, sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n\n    border-radius: 2px;\n    background: #e9ecef;\n\n    &.active {\n      background: #ced4da;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screens": `styles_screens__oTO6a`,
	"screen": `styles_screen__MoQw+`,
	"active": `styles_active__a7QO3`
};
export default ___CSS_LOADER_EXPORT___;
