import { Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectModulesBundles } from 'store/modules/slice';
import { ModulesBundle } from 'store/modules/types';
import {
  ModulesBundleWithSelection,
  isEntireBundleSelected,
  setEntireBundleSelection, setGroupSelection, setModuleSelection, updateBundles, validateSelection,
} from './helpers';

export enum ActionType {
  UPDATED_BUNDLES,
  TOGGLE_ALL,
  TOGGLE_MODULE,
  TOGGLE_GROUP,
}

export type Action = { type: ActionType.UPDATED_BUNDLES; data: ModulesBundle[] | undefined }
  | { type: ActionType.TOGGLE_MODULE; bundleIndex: number; moduleIndex: number }
  | { type: ActionType.TOGGLE_ALL; bundleIndex: number }
  | { type: ActionType.TOGGLE_GROUP; bundleIndex: number; groupIndex: number };

type State = ModulesBundleWithSelection[];

const initialState: State = [];

function reducer(state: State, action: Action): State {
  switch (action.type) {

    case ActionType.UPDATED_BUNDLES: {
      return updateBundles(state, action.data ?? []);
    }

    case ActionType.TOGGLE_MODULE: {
      const { bundleIndex, moduleIndex } = action;
      const isSelected = state[bundleIndex]?.modules[moduleIndex]?.selected;

      return setModuleSelection(state, bundleIndex, moduleIndex, !isSelected);
    }

    case ActionType.TOGGLE_ALL: {
      const { bundleIndex } = action;
      const isAllSelected = isEntireBundleSelected(state[bundleIndex]);

      return setEntireBundleSelection(state, bundleIndex, !isAllSelected);
    }

    case ActionType.TOGGLE_GROUP: {
      const { bundleIndex, groupIndex } = action;
      const isSelected = state[bundleIndex]?.groups[groupIndex]?.selected;

      return setGroupSelection(state, bundleIndex, groupIndex, !isSelected);
    }

    default:
      console.error('Wrong useBundles action', action); // eslint-disable-line no-console

      return state;
  }
}

export type UseBundlesReturnType = {
  bundles: ModulesBundleWithSelection[];
  bundlesIsActive: boolean;
  bundlesDispatch: Dispatch<Action>;
  selectedCountModulesTotal: number;
  selectionIsValid: boolean;
  activeBundleIndex: number;
  setActiveBundleIndex: (idx: number) => void;
  setNextBundleActive: undefined | (() => void);
  setPrevBundleActive: undefined | (() => void);
};

export default function useBundles(): UseBundlesReturnType {
  const bundlesFromRedux = useAppSelector(selectModulesBundles);

  const [bundles, bundlesDispatch] = useReducer(reducer, initialState);
  const [bundlesIsActive, setBundlesIsActive] = useState(false);

  const [activeBundleIndex, setActiveBundleIndex] = useState<number>(-1);
  const [bundlesIndexesList, setBundlesIndexesList] = useState<number[]>([]);

  useEffect(() => {
    bundlesDispatch({ type: ActionType.UPDATED_BUNDLES, data: bundlesFromRedux });
    setBundlesIsActive(Boolean(bundlesFromRedux));
    const indexes = (bundlesFromRedux ?? []).flatMap(bundle => bundle.modules.length > 1 ? [bundle.index] : []);
    setBundlesIndexesList(indexes);
    if (!indexes.length) {
      setActiveBundleIndex(bundlesFromRedux?.length ? 0 : -1);

      return;
    }
    if (activeBundleIndex < 0) {
      setActiveBundleIndex(indexes[0]);
    }
  }, [bundlesFromRedux]);

  const bundlesIndexesCallbacks = useMemo(
    () => {
      const idx = bundlesIndexesList.findIndex(i => i === activeBundleIndex);
      const nextIndex = idx > -1 && (idx + 1) < bundlesIndexesList.length ? bundlesIndexesList[idx + 1] : -1;
      const prevIndex = idx > 0 ? bundlesIndexesList[idx - 1] : -1;
      const createHandler = (index: number): (() => void) | undefined => index > -1
        ? (): void => setActiveBundleIndex(index)
        : undefined;

      return {
        setNextBundleActive: createHandler(nextIndex),
        setPrevBundleActive: createHandler(prevIndex),
      };
    },
    [bundlesIndexesList, activeBundleIndex],
  );

  const selection = useMemo(
    () => ({
      selectedCountModulesTotal: bundles.reduce((selected, bundle) => selected + bundle.selectedCount, 0),
      selectionIsValid: validateSelection(bundles),
    }),
    [bundles],
  );

  return {
    bundles,
    bundlesIsActive,
    bundlesDispatch,
    ...selection,
    activeBundleIndex,
    setActiveBundleIndex,
    ...bundlesIndexesCallbacks,
  };
}
