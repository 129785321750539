import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

export type SendRecipePayload = {
  header: {
    jobZeroDocNumber: string;
    jobZeroDocName: string;
    moduleBundleDocNumber: string;
    moduleBundleDocName: string;
    modularTemplateDocNumber: string;
    ownerUserName: string;
    ownerEmail: string;
    estimatedReviewStartDate: string;
    originator: string;
    timestamp: string;
  };
  // one recipe consists of array of modules - single permutation
  // several recipes will consists of array of array of modules - array of permutations
  permutations: {
    zone: string;
    zoneScreenName: string;
    moduleDocNumber: string;
    moduleScreenName?: string;
  }[][];
  textCollection: {
    assetNumber: string;
    zone: string;
    screen: string;
  };
};

export const sendRecipe = async (
  sessionId: string,
  payload: SendRecipePayload,
): Promise<boolean> => {
  try {
    const { data } = await apiInstance.post<{ success: boolean }>(
      API.SEND_RECIPE,
      payload,
      { headers: getAuthorizationHeader(sessionId) },
    );

    return data.success;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
};

export type ExportRecipePayload = {
  header: {
    jobZeroDocNumber: string;
    jobZeoroResourceType: string;
    moduleBundleDocNumber: string;
    moduleBundleDocName: string;
    moduleBundleProduct: string[];
    timestamp: string;
  };
  permutations: SendRecipePayload['permutations'];
};

export async function exportRecipe(
  sessionId: string,
  payload: ExportRecipePayload,
): Promise<{ blob: Blob; filename?: string }> {
  try {
    const response = await apiInstance.post(
      '/export-recipe',
      payload,
      {
        headers: getAuthorizationHeader(sessionId),
        responseType: 'blob',
      },
    );

    const contentDisposition = response.headers['content-disposition'] as string | undefined;
    let filename: string | undefined;
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      // extract filename from the content-disposition header: attachment; filename="workbook.xlsx""
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches?.[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return { filename, blob: new Blob([response.data]) };
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}
