// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton_radioButton__eQWPJ {
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border: 1px solid #CED0DB;
  border-radius: 50%;
  transform: translateY(-0.075em);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.RadioButton_radioButton__eQWPJ::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #00857C;
  box-shadow: inset 1em 1em var(--form-control-color);
}
.RadioButton_radioButton__eQWPJ:checked {
  border-color: #00857C;
}
.RadioButton_radioButton__eQWPJ:checked::before {
  transform: scale(1);
}
.RadioButton_radioButton__wrapper__enTUb {
  height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/RadioButton/RadioButton.module.scss"],"names":[],"mappings":"AAGA;EACI,gBAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,+BAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAII;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,uCAAA;EACA,yBAAA;EACA,mDAAA;AAFR;AAKI;EACI,qBAAA;AAHR;AAIQ;EACI,mBAAA;AAFZ;AAMI;EACI,YAAA;AAJR","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n@import 'styles/_colors.module';\n\n.radioButton {\n    appearance: none;\n    background-color: white;\n    margin: 0;\n    font: inherit;\n    color: currentColor;\n    width: 18px;\n    height: 18px;\n    border: 1px solid map-get($gray , 100);\n    border-radius: 50%;\n    transform: translateY(-0.075em);\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n\n    &::before {\n        content: \"\";\n        width: 12px;\n        height: 12px;\n        border-radius: 50%;\n        transform: scale(0);\n        transition: 120ms transform ease-in-out;\n        background-color: map-get($green , 300);\n        box-shadow: inset 1em 1em var(--form-control-color);\n    }\n\n    &:checked {\n        border-color: map-get($green , 300);\n        &::before {\n            transform: scale(1);\n        }\n    }\n\n    &__wrapper {\n        height: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButton": `RadioButton_radioButton__eQWPJ`,
	"radioButton__wrapper": `RadioButton_radioButton__wrapper__enTUb`
};
export default ___CSS_LOADER_EXPORT___;
