// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateCard_TemplateCard__AzkaU {
  width: 416px;
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  padding: 17px 17px 49px;
  flex: 0 1 32%;
  margin: 12px 2% 12px 0;
}
.TemplateCard_TemplateCard__AzkaU:nth-child(3n) {
  margin-right: 0;
}
.TemplateCard_TemplateCard__AzkaU:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
}
.TemplateCard_TemplateCard__AzkaU .TemplateCard_thumbnail__wwZmf {
  width: 80px;
  height: 80px;
  margin: 50px 0 18px;
}
.TemplateCard_TemplateCard__AzkaU .TemplateCard_details__xYUqq {
  font-family: Helvetica Neue, Roboto, Arial, sans-serif;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #212529;
  line-height: 120%;
}`, "",{"version":3,"sources":["webpack://./src/components/TemplateCard.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qCAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,4CAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,sDAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AAHJ","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.TemplateCard {\n  width: 416px;\n  height: 238px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid rgba(0, 0, 0, 0.17);\n  border-radius: 8px;\n  padding: 17px 17px 49px;\n  flex: 0 1 32%;\n  margin: 12px 2% 12px 0;\n\n  &:nth-child(3n) {\n    margin-right: 0;\n  }\n\n  &:hover {\n    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);\n  }\n\n  .thumbnail {\n    width: 80px;\n    height: 80px;\n    margin: 50px 0 18px;\n  }\n\n  .details {\n    font-family: Helvetica Neue, Roboto, Arial, sans-serif;\n    text-align: center;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    color: #212529;\n    line-height: 120%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TemplateCard": `TemplateCard_TemplateCard__AzkaU`,
	"thumbnail": `TemplateCard_thumbnail__wwZmf`,
	"details": `TemplateCard_details__xYUqq`
};
export default ___CSS_LOADER_EXPORT___;
