import { put, call, takeLatest, select } from 'redux-saga/effects';
import { sendRecipe } from 'api/sendRecipe';
import { getSessionId } from 'utils';
import { selectSelectedTextCollection, selectModulesInfo, selectLastSelectedBundleWithCluster } from './modules/slice';
import { GetRecipeRequestPayload } from './recipe';
import {
  getRecipeError,
  getRecipeRequest,
  getRecipeStart,
  getRecipeSuccess,
} from './recipeSlice';
import { selectCurrentUserEmail, selectOwnerEmail } from './userSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* recipeSaga(params: PayloadAction<GetRecipeRequestPayload>): SagaIterator {
  const { payload } = params;
  try {
    yield put(getRecipeStart());
    const sessionId: string = yield call(getSessionId);
    const info: ReturnType<typeof selectModulesInfo> = yield select(selectModulesInfo);
    if (!info) {
      throw new Error('Missing modules info');
    }
    const { jobZero, moduleBundle } = info;
    const ownerEmail = yield select(selectOwnerEmail(payload.owner));
    const originator = yield select(selectCurrentUserEmail);

    const selectedTextCollection: ReturnType<typeof selectSelectedTextCollection>
      = yield select(selectSelectedTextCollection);

    const lastSelectedBundle: ReturnType<typeof selectLastSelectedBundleWithCluster>
      = yield select(selectLastSelectedBundleWithCluster);

    const apiPayload = {
      header: {
        jobZeroDocNumber: jobZero.number,
        jobZeroDocName: jobZero.name,
        moduleBundleDocNumber: moduleBundle.number,
        moduleBundleDocName: moduleBundle.name,
        modularTemplateDocNumber: '', // currently it will be always empty string
        ownerUserName: payload.owner,
        ownerEmail,
        estimatedReviewStartDate: payload.reviewDate,
        originator,
        timestamp: payload.timestamp,
      },
      permutations: payload.permutations,
      textCollection: {
        assetNumber: selectedTextCollection?.assetNumber ?? '',
        zone: lastSelectedBundle?.clusterSurfaceName ?? '',
        screen: Number.isSafeInteger(lastSelectedBundle?.cluster)
          ? `Screen ${lastSelectedBundle?.cluster as number}` : '',
      },
    };

    const success: boolean = yield call(sendRecipe, sessionId, apiPayload);

    yield put(getRecipeSuccess(success));
  } catch (err) {
    yield put(getRecipeError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [takeLatest(getRecipeRequest.type, recipeSaga)];
