import dayjs from 'dayjs';
import { ExportRecipePayload, exportRecipe } from 'api/sendRecipe';
import store from 'store';
import { selectModulesInfo } from 'store/modules/slice';
import { getSessionId } from 'utils';

export const downloadExportedRecipe = async (
  permutations: ExportRecipePayload['permutations'],
): Promise<void> => {
  const storeState = store.getState();
  const info = selectModulesInfo(storeState);
  if (!info) {
    throw new Error('Missing modules info');
  }
  const { jobZero, moduleBundle } = info;

  const { blob, filename } = await exportRecipe(
    getSessionId(),
    {
      header: {
        jobZeroDocNumber: jobZero.number,
        jobZeoroResourceType: jobZero.resourceType,
        moduleBundleDocNumber: moduleBundle.number,
        moduleBundleDocName: moduleBundle.name,
        moduleBundleProduct: moduleBundle.product,
        timestamp: dayjs().format('YYYY-MM-DDTHH-mm-ss'),
      },
      permutations,
    },
  );

  const urlBlob = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = filename ?? 'recipe.xlsx';
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(urlBlob);
};
