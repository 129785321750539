// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_artboard__an6C6 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 45%;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  overflow-y: auto;
}

.styles_placeholder__1iC0m {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/Artboard/styles.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;EAEA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAKA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;AAFF","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.artboard {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  padding-bottom: 45%;\n\n  height: 100%;\n  width: 100%;\n  background: #f5f5f5;\n  overflow-y: auto;\n}\n\n// instead of padding, otherwise it impacts sticky position\n.placeholder {\n  height: 48px;\n  min-height: 48px;\n  max-height: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"artboard": `styles_artboard__an6C6`,
	"placeholder": `styles_placeholder__1iC0m`
};
export default ___CSS_LOADER_EXPORT___;
