import React from 'react';

export const TextCollectionNoAttached: React.FC = () => {

  return (
    <div>
      <p>No text collection linked to the module bundle. There is a chance that Indication(s)
        and SSI content might not be populated to the final permutations.</p>
      <p>Please review that selected job0 already contains Indication(s) and SSI content in Veeva PromoMats.</p>
    </div>
  );
};
