import React, { useEffect, useRef } from 'react';
import Switcher from 'components/Switcher/Switcher';
import styles from '../Modules.module.scss';

type Props = {
  id: string;
  title: string;
  selectedNumber: number;
  totalNumber: number;
  onChange: () => void;
  onCreateGroup?: () => void;
  toggleNumbersHandler: (value: boolean) => void;
};

const BundleListTitle: React.FC<Props> = (props) => {
  const {
    id,
    title,
    selectedNumber,
    totalNumber,
    onChange,
    onCreateGroup,
    toggleNumbersHandler,
  } = props;
  const htmlId = `variant-${id}`;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.indeterminate =
      totalNumber > 0 && selectedNumber > 0 && selectedNumber < totalNumber;
  }, [selectedNumber, totalNumber]);

  return (
    <div
      className={`d-flex justify-content-between align-items-center mb-3 p-2 rounded-2 ${styles.modulesListTitle}`}
    >
      <div className={'d-flex align-items-center'}>
        <div>
          <input
            className="form-check-input custom-check me-2"
            type="checkbox"
            value=""
            id={htmlId}
            ref={ref}
            onChange={onChange}
            checked={totalNumber === selectedNumber}
            disabled={totalNumber === 0}
          />
          <label className="form-check-label fw-bold" htmlFor={htmlId}>
            {`${selectedNumber}/${totalNumber} ${title}`}
          </label>
        </div>

        <div className={styles.switcherWrapper}>
          <Switcher onChange={toggleNumbersHandler} lable={'Show Asset ID'} />
        </div>
      </div>

      {onCreateGroup && totalNumber > 1 && (
        <button
          className="me-3 btn btn-outline-primary custom-button"
          onClick={onCreateGroup}
          type="button"
        >
          Create Module Group
        </button>
      )}
    </div>
  );
};

export default BundleListTitle;
