type AwaitedDeep<T> = T extends Promise<infer U>
  ? AwaitedDeep<U>
  : T extends (infer V)[]
    ? AwaitedDeep<V>[]
    : T extends object
      ? { [K in keyof T]: AwaitedDeep<T[K]> }
      : T;

export async function waitForAllNestedPromises<T, D>(
  obj: T,
  defaultValue: D,
): Promise<AwaitedDeep<T>> {
  if (obj instanceof Promise) {
    try {
      // Attempt to resolve the promise
      return await waitForAllNestedPromises(await obj, defaultValue);
    } catch {
      // On rejection, return the default value
      return defaultValue as AwaitedDeep<T>;
    }
  } else if (Array.isArray(obj)) {
    // If the object is an array, resolve each item with error handling
    const resolvedArray = await Promise.all(
      obj.map(async (item) => {
        try {
          return await waitForAllNestedPromises(item, defaultValue) as D;
        } catch {
          return defaultValue;
        }
      }),
    );

    return resolvedArray as AwaitedDeep<T>;
  // eslint-disable-next-line sonarjs/different-types-comparison
  } else if (typeof obj === 'object' && obj !== null) {
    // If the object is a non-null object, resolve each key-value pair with error handling
    const entries = await Promise.all(
      Object.entries(obj).map(async ([key, value]) => {
        try {
          const resolvedValue = await waitForAllNestedPromises(value, defaultValue);

          return [key, resolvedValue] as const;
        } catch {
          return [key, defaultValue] as const;
        }
      }),
    );

    return Object.fromEntries(entries) as AwaitedDeep<T>;
  }

  // If the current value is neither a Promise, array, nor object, return it as is
  return obj as AwaitedDeep<T>;
}
