import dayjs from 'dayjs';
import { inflect } from 'inflection';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { Combobox } from 'react-widgets/esm';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import { RecipeCombination } from 'pages/Combinations/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getRecipeRequest,
  selectRecipeData,
  selectRecipeDataLoadingStatus,
  selectRecipeError,
} from 'store/recipeSlice';
import { selectCurrentUserName, selectUserNames } from 'store/userSlice';
import Button from '../../../components/Button/Button';
import styles from './RecipeModal.module.scss';

interface ModalProps {
  permutations: RecipeCombination[];
  onRecipeSuccess?: () => void;
  onModalClose: () => void;
}

const Backdrop: React.FC<Pick<ModalProps, 'onModalClose'>> = ({ onModalClose }) => {
  return <AccessibleDiv className={styles.backdrop} onClick={onModalClose} onKeyUp={(): void => undefined}/>;
};

const ModalOverlay: React.FC<ModalProps> = ({ onModalClose, permutations, onRecipeSuccess }) => {
  const dispatch = useAppDispatch();
  const userNames = useAppSelector(selectUserNames);
  const currentUser = useAppSelector(selectCurrentUserName);
  const recipeLoading = useAppSelector(selectRecipeDataLoadingStatus);
  const recipeSuccess = useAppSelector(selectRecipeData);
  const recipeError = useAppSelector(selectRecipeError);
  const [owner, setOwner] = useState(currentUser);
  const [ownerError, setOwnerError] = useState(false);

  const onNumberChangeHandler = (value: string): void => {
    setOwnerError(false);
    setOwner(value);
  };

  const onFormSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    setOwnerError(false);

    const ownerErr = !userNames.includes(owner);

    if (ownerErr) {
      setOwnerError(true);

      return;
    }

    dispatch(
      getRecipeRequest({
        owner,
        reviewDate: dayjs().add(2, 'day').format('YYYY-MM-DD'),
        timestamp: dayjs().format('YYYY-MM-DDTHH-mm-ssZZ[Z]'),
        permutations,
      }),
    );
  };

  useEffect(() => {
    if (recipeSuccess) {
      onModalClose();
      onRecipeSuccess?.();
      toast(
        `Selected combinations (${permutations.length}) have been sent to be built`
        + ' and should be completed in about 2 days.'
        + '\nYou will be notified via email when the building process is complete',
        { hideProgressBar: true },
      );
    }
  }, [recipeSuccess]);

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <div>
          <h1 className="fs-5">Confirm Information Below</h1>
          <p className="mb-0">
            {permutations.length} {inflect('combination', permutations.length)}
          </p>
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onModalClose}
        />
      </div>
      <div className={styles.modalBody}>
        <div className={styles.alert}>
          Once the combinations have been confirmed, you cannot undo it.
        </div>
        <form
          className={styles.form}
          onSubmit={onFormSubmitHandler}
          id="recipe-form"
        >
          <div className={styles.ownerContainer}>
            <label htmlFor="owner_input" className={styles.label}>
              Owner
            </label>
            <Combobox
              data={userNames}
              filter="contains"
              value={owner}
              onChange={onNumberChangeHandler}
              id="owner"
              containerClassName={ownerError ? 'input-error' : ''}
              disabled={recipeLoading}
            />
            {ownerError ? (
              <div className={styles.error}>
                Specified user doesn’t exist. Select an owner from the list.
              </div>
            ) : (
              <div className={styles.tip}>
                Specify yourself or another person as an owner
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        {recipeError && (
          <div className={styles.networkError}>
            Something has gone wrong. Please try again in 30 seconds
          </div>
        )}
        <Button
          type="submit"
          isDisabled={false}
          label="Build"
          styleOverride={{ width: '105px' }}
          formId="recipe-form"
          isLoading={recipeLoading}
        />
      </div>
    </div>
  );
};

const RecipeModal: React.FC<ModalProps> = ({ onModalClose, permutations, onRecipeSuccess }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onModalClose={onModalClose} />,
        document.getElementById('backdrop-root') as HTMLDivElement,
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onModalClose={onModalClose}
          permutations={permutations}
          onRecipeSuccess={onRecipeSuccess}
        />,
        document.getElementById('modal-root') as HTMLDivElement,
      )}
    </>
  );
};

export default RecipeModal;
