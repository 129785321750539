import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectModulesLoadingStatus,
  selectModulesError,
  selectModulesInfo,
  actions,
} from 'store/modules/slice';
import BundleForm from './components/BundleForm';
import styles from './Modules.module.scss';
export { moduleTypeTestId } from './components/BandleTabs';

const getParams = (
  modal: { jobZeroId: string; moduleBundleId: string } | undefined, queryParams: URLSearchParams,
): [string, string] => {
  if (modal) {
    return [modal.jobZeroId, modal.moduleBundleId];
  } else {
    return [queryParams.get('jobzero') ?? '', queryParams.get('bundle') ?? ''];
  }
};

const validateParams = (jobZeroId: string, moduleBundleId: string): boolean => !!(jobZeroId && moduleBundleId);

type Props = {
  modal?: {
    jobZeroId: string;
    moduleBundleId: string;
    onFormSubmit: () => void;
    isNoMoreToAdd: boolean;
  };
};

const Modules: React.FC<Props> = ({ modal }) => {
  const [queryParams] = useSearchParams();
  const [jobZeroId, moduleBundleId] = getParams(modal, queryParams);

  const dispatchToRedux = useAppDispatch();
  const dataInfo = useAppSelector(selectModulesInfo);
  const dataLoading = useAppSelector(selectModulesLoadingStatus);
  const dataError = useAppSelector(selectModulesError);

  useEffect(() => {
    const sameParams = dataInfo?.jobZero.id === jobZeroId && dataInfo?.moduleBundle.id === moduleBundleId;
    if (!sameParams && validateParams(jobZeroId, moduleBundleId)) {
      dispatchToRedux(actions.getModulesRequest({
        jobZeroId,
        moduleBundleId,
      }));
    }
  }, [jobZeroId, moduleBundleId]); // dataInfo intentionally skipped from the deps

  const onBrandClick = (): void => {
    dispatchToRedux(actions.resetModules());
  };

  const isInvalidToRender = !validateParams(jobZeroId, moduleBundleId) || dataError;
  if (isInvalidToRender) {
    if (modal) {
      return (
        <div className='text-danger'>
          {`Invalid parameters ${jobZeroId}/${moduleBundleId}`}
        </div>
      );
    }

    return <Navigate to="/" />;
  }

  if (dataLoading) {
    return (
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  if (!dataInfo) {
    return (<div>Failed to load the jobZero or moduleBundle</div>);
  }

  const { jobZero } = dataInfo;

  if (modal?.isNoMoreToAdd) {
    return (
      <>
        <NavBar textContent={jobZero.name} />
        <main
          className={`container-fluid mx-lg-5 px-3 ${styles.contentContainer}`}
        >
          <h4 className="p-0 m-0 mb-1" style={{ width: 'auto' }}>
            Select the modules you want to use
          </h4>
          <p className="p-0 m-0 pb-3 border-bottom">
            Select at least one module. Indication(s) and SSI content will be added automatically.
          </p>
          <div
            className="container d-flex align-items-center justify-content-center"
            style={{ flex: '1 1 0', fontWeight: 500 }}
          >
            All possible combinations have been added
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <NavBar
        textContent={jobZero.name}
        onBrandClick={onBrandClick}
      />
      <main className={`container-fluid mx-lg-5 px-3 ${styles.contentContainer}`} >
        <h4 className="p-0 m-0 mb-1" style={{ width: 'auto' }}>
          Select the modules you want to use
        </h4>
        <p className="p-0 m-0 pb-3 border-bottom">
          Select at least one module. Indication(s) and SSI content will be added automatically.
        </p>

        <BundleForm modal={modal} />
      </main>
    </>
  );
};

export default Modules;
