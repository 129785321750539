import _ from 'lodash';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Screen from 'components/Screen';

import type { Screen as ScreenType, TemplateSection, Layout, ScreenToView } from 'types';
import styles from './styles.module.scss';

interface ArtboardProps {
  screens: ScreenType[];
  screensRefs: React.MutableRefObject<Record<string, HTMLDivElement>>;
  onCnahgeLayoutVariant: (section: TemplateSection, layout: Layout | null, screen: ScreenToView) => void;
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

const Artboard = forwardRef<HTMLDivElement, ArtboardProps>(({
  screensRefs,
  screens,
  onScroll,
  onCnahgeLayoutVariant,
}, ref) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => scrollContainerRef.current!, []);

  return (
    <div
      ref={scrollContainerRef}
      className={styles.artboard}
      onScroll={onScroll}
    >
      <div className={styles.placeholder} />
      {_.map(screens, ({ id, sections }) => (
        <Screen
          key={id}
          ref={(node: HTMLDivElement): void => {
            screensRefs.current[id] = node;
          }}
          screenId={id}
          sections={sections}
          onChangeLayoutVariant={onCnahgeLayoutVariant}
          scrollContainer={scrollContainerRef}
        />
      ))}
    </div>
  );
});

Artboard.displayName = 'Artboard';

export default Artboard;
