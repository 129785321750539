import classNames from 'classnames';
import React from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import { useAppSelector } from 'store/hooks';
import { selectActiveScreen } from 'store/simulationSlice';
import { Screen as ScreenType } from 'types';

import styles from './styles.module.scss';

interface NavigationPagesProps {
  screens: ScreenType[];
  onScreenClick: (screenId: string) => void;
}

const NavigationScreens: React.FC<NavigationPagesProps> = ({ screens, onScreenClick }) => {
  const activeScreenId = useAppSelector(selectActiveScreen);

  return (
    <div className={styles.screens}>
      {screens.map(({ id }, index) => {
        const isScreenActive = activeScreenId ? activeScreenId === id : index === 0;

        return (
          <AccessibleDiv
            className={classNames(
              styles.screen,
              {
                [styles.active]: isScreenActive,
              },
            )}
            key={id}
            onClick={(): void => onScreenClick(id)}
          >
            {index + 1}
          </AccessibleDiv>
        );
      })}
    </div>
  );
};

export default NavigationScreens;
