import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actions, selectSelectedTextCollection, selectTextCollection } from 'store/modules/slice';
import { TextCollection } from 'store/modules/types';
import { saveSelectedTextCollectionSessionStorage } from 'utils/selectedTextCollectionSessionStorage';
import { TextCollectionNoAttached } from './TextCollectionNoAttached';
import { TextCollectionsTable } from './TextCollectionsTable';

type Props = {
  isOpen: boolean;
  onSelect?: () => void;
};

export const TextCollectionModal: React.FC<Props> = ({ isOpen, onSelect }) => {
  const dispatchToRedux = useAppDispatch();

  const textCollections = useSelector(selectTextCollection);
  const isTextCollections = textCollections?.length > 0;
  const selectedTextCollectionInState = useAppSelector(selectSelectedTextCollection);

  const [selectedLocalTextCollection, setSelectedLocalTextCollection] = useState(selectedTextCollectionInState);

  const onCloseHandler = (): void => {
    setSelectedLocalTextCollection(selectedTextCollectionInState);
    dispatchToRedux(actions.toggleTextCollectionModal(false));
  };
  const onSelectHandler = (): void => {
    saveSelectedTextCollectionSessionStorage(isTextCollections, selectedLocalTextCollection);
    dispatchToRedux(actions.setSelectedTextCollection(selectedLocalTextCollection));
    onSelect?.();

    dispatchToRedux(actions.toggleTextCollectionModal(false));
  };

  const onChangeTable = (textCollection: TextCollection): void => {
    setSelectedLocalTextCollection(textCollection);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      size={isTextCollections ? 'xl' : 'lg'}
      maxWidth={790}
      title={isTextCollections ? 'Select Text Collection' : 'No Text Collection was found'}
      tooltipText={isTextCollections
        ? 'Indication(s) and SSI content will be added from selected Text Collection automatically'
        : undefined
      }
    >
      <div className="modal-body">
        {
          isTextCollections
            ? (<TextCollectionsTable
              onChangeTable={onChangeTable}
              textCollections={textCollections}
              selectedTextCollection={selectedLocalTextCollection}
            />)
            : (<TextCollectionNoAttached />)
        }

      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-start">
          <button
            className="me-3 btn btn-outline-primary custom-button"
            onClick={onCloseHandler}
            type="button"
          >
            <span>Cancel</span>
          </button>

          {
            isTextCollections
              ? (<Button
                label="Select"
                type="button"
                isDisabled={!selectedLocalTextCollection}
                onClick={onSelectHandler}
              />)
              : (<Button
                label="Continue"
                type="button"
                onClick={onSelectHandler}
              />)
          }
        </div>
      </div>
    </Modal>
  );
};
