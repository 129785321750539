import { PayloadAction } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';

export type ModulesSection = {
  surface: {
    id: string;
    name: string;
  };
  section: {
    id: string;
    name: string;
    displayname: string;
  };
  placeholder?: {
    index: number;
    name: string;
  };
  bundleIndexes: number[];
};

export type ModulesBundleModule = {
  layoutId: string;
  id: string;
  number: string;
  classification: string;
  index: number;
  selected: boolean;
  disabled: boolean;
};

export type ModulesBundleGroup = {
  index: number;
  hasDuplicates: boolean;
  selected: boolean;
  modules: Omit<ModulesBundleModule, 'selected' | 'disabled'>[];
};

export type ModulesBundle = {
  id: string;
  name: string;
  type: string;
  modules: ModulesBundleModule[];
  groups: ModulesBundleGroup[]; // sparse array
  index: number;
};

export type ModulesActioinRequestPayload = {
  jobZeroId: string;
  moduleBundleId: string;
};

export type ModulesPersistentBundleGroups = {
  bundleIndex: number;
  groups: number[][];
};

export type TextCollectionType = {
  id: string;
  name: string;
  title: string;
  assetNumber: string;
};

export type ModulesState = {
  info?: {
    jobZero: {
      id: string;
      name: string;
      number: string;
      resourceType: string;
    };
    moduleBundle: {
      id: string;
      name: string;
      number: string;
      product: string[];
    };
  };
  textCollectionMetadata: TextCollectionType[];
  isTextCollectionModalOpen: boolean;
  sections?: ModulesSection[];
  bundles?: ModulesBundle[];
  status: SAGA_STATUS;
  error: string;
  // key is an identifier of jobzero and bundle: jobzero=902113&bundle=902110
  // value is an array (not sparse) of groups for bundles
  groups: Record<string, ModulesPersistentBundleGroups[]>;
};

export type CreateGroupActionPayload = PayloadAction<{
  bundleIndex: number;
  moduleIndexes: number[];
}>;

export type RemoveGroupActionPayload = PayloadAction<{
  bundleIndex: number;
  groupIndex: number;
}>;

export type ShiftModuleInGroupActionPayload = PayloadAction<{
  bundleIndex: number;
  groupIndex: number;
  moduleIndex: number;
  shift: number;
}>;

export enum ModulesTypeList {
  PROMOTIONAL_MESSAGE = 'Promotional Message',
}
